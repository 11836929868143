import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { useDetectAdBlock } from "adblock-detect-react";

export default function HelpPage(): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "catfish", "abm"]}
        path={RouteFactory.help}
      />

      <MetaTags
        title="Help"
        description="Read more at the Business Times."
        slug={RouteFactory.help}
      />

      <GAData
        title="Help"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <div
        className="container !mt-6 px-3 font-poppins"
        data-testid="help-component"
      >
        <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            Frequently Asked Questions
          </h3>

          <p>
            {
              "If you can’t find the answer to your question here, please call us at "
            }
            <strong>(65) 6388 3838</strong> or email{" "}
            <a
              href="mailto:btocs@sph.com.sg"
              className="text-verticals-btblue hover:text-black hover:underline"
            >
              btocs@sph.com.sg
            </a>
            . The phone line is manned from 8am-6pm on weekdays, Singapore time
            (UTC + 8hrs).
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              <a
                href="#chapter2"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                The Business Times website
              </a>
            </li>
            <li>
              <a
                href="#chapter3"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                {"BT's mobile apps (iOS, Android)"}
              </a>
            </li>
            <li>
              <a
                href="#chapter4"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                Subscription (newspaper and digital)
              </a>
            </li>
            <li>
              <a
                href="#chapter5"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                Newsletters and social media
              </a>
            </li>
          </ul>

          <hr className="my-4 border-t border-gray-850 opacity-25" />

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            <a id="chapter2">The Business Times website</a>
          </h3>

          <p>
            <strong>Where can I access businesstimes.com.sg?</strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              On all web browsers on any desktop computer, laptop, tablet, or
              smartphone.
            </li>
            <li>
              {
                "For the best and most readable display, use a popular browser (Safari, Chrome, or Firefox) and ensure that it's updated to the latest version. "
              }
            </li>
          </ul>

          <p>
            <strong>When is the website updated each day?</strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                " The news in each day's newspaper is available on the website at 7.00am, Singapore time (2300 UTC). But the website is also updated throughout the day, with breaking news stories filed by BT's reporters. "
              }
            </li>
          </ul>

          <p>
            <strong>
              Do I need a subscription to read{" "}
              <a
                href="https://www.businesstimes.com.sg"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                businesstimes.com.sg
              </a>
              ?
            </strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                " Most of BT's breaking news stories and a daily selection of three stories from the print edition of BT are available for all to read on our website. However, subscribers get extensive, full access to all premium stories from the print edition of BT, our latest scoops online, interactive graphics, and in-depth news features and opinion pieces. All premium content is marked with a "
              }{" "}
              {"PREMIUM"} label. Subscribe online
              <a
                href="http://www.sphsubscription.com.sg/eshop/?r=products/newsubscriptionpackages&amp;pcode=BT&amp;utm_medium=online&amp;utm_source=help&amp;utm_campaign=btproductpage&amp;utm_term=tactical#publication-section-5"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                here
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              Is The Business Times website available on mobile and tablet
              devices?
            </strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                " BT is now available on web browsers on any desktop computer, laptop, tablet, or smartphone. The website is responsive, which means that it adapts to fit the size of your device's display. No more pinching and zooming to read stories on your tablet or phone. We also have mobile apps for tablets and smartphones. You can download our "
              }
              <a
                href="https://apps.apple.com/us/app/the-business-times/id531275824"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                iOS app
              </a>{" "}
              {"from Apple's App Store, and our "}
              <a
                href="https://play.google.com/store/apps/details?id=com.ps.bt&amp;hl=en_SG&amp;gl=US"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                Android app
              </a>
              {" from Google’s Play Store."}
            </li>
          </ul>

          <p>
            <strong>{'Why should I "log in"?'}</strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              If you have subscribed to{" "}
              <a
                href="www.businesstimes.com.sg"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                businesstimes.com.sg
              </a>
              {
                ", you should log in (top right-hand corner of the home page) so that you can get full access to every story that has been published in the print edition of the paper, as well as more in-depth breaking news. Selecting “remember me” ensures that you won't need to key in your username and password again when youwant to access BT on that device. "
              }
            </li>
          </ul>

          <p>
            <strong>I have forgotten my username. What should I do?</strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              Please call our customer service team at{" "}
              <strong>(65) 6388 3838</strong>, or email{" "}
              <a
                href="mailto:btocs@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              I have forgotten my password. How should I reset it?
            </strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                'Click on "Log In" at the upper right-hand corner of the screen. You will see "Forgot password?" on the login panel. Click that and follow the instructions from there. Alternatively, request a new password online'
              }
              <a
                href="http://sphreg.asiaone.com/RegAuth2/enLogin.html"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                here
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              The home page seems to be displaying news that is outdated. Why?
            </strong>
          </p>

          <ul
            className="pl-30 list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                'This could be because your computer\'s hard disk has saved cache copies of the web pages. You can clear these by clicking the "refresh" or "reload" button of the browser you are using. If you still do not see the latest page, try these steps: '
              }
              <ol>
                <li>
                  <em>For Chrome users</em>:{" "}
                  {
                    'Under the "Tools" menu (the icon comprising three short horizontal lines in the top right-hand corner of the screen), select "Settings", then "Show advanced settings", then click "Clear browsing data". '
                  }
                </li>
                <li>
                  <em>For Internet Explorer users:</em>{" "}
                  {
                    'Under the "Tools" menu bar, click "Internet Options". Under "General", find "Delete Browsing History" and click "Delete Temporary Internet Files".'
                  }
                </li>
                <li>
                  <em>For Firefox users:</em>{" "}
                  {
                    'Under the "Tools" menu, click on "Cached Web Content". Under "Options", choose the "Advanced" panel, and "Network" tab. Click "Clear Now". '
                  }
                </li>
                <li>
                  Refresh or reload the website on your browser again.&nbsp;
                </li>
              </ol>
            </li>
          </ul>
          <hr className="my-4 border-t border-opacity-25" />

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            <a
              href="#chapter3"
              className="text-verticals-btblue hover:text-black hover:underline"
            >
              The Business Times mobile apps
            </a>
          </h3>

          <p>
            <strong>Is The Business Times available on mobile devices?</strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                "BT is now available on web browsers on any desktop computer, laptop, tablet, or smartphone. The website is responsive, which means that it responds to fit the size of your device's display screen - no more pinching and zooming to read stories. We also have apps for tablets and smartphones. You can download our "
              }
              <a
                href="https://apps.apple.com/us/app/the-business-times/id531275824"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                iOS app
              </a>
              {" from Apple's App Store, and our "}
              <a
                href="https://play.google.com/store/apps/details?id=com.ps.bt&amp;hl=en_SG&amp;gl=US"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                Android app
              </a>
              {" from Google’s Play Store."}
            </li>
          </ul>

          <p>
            <strong>
              Do I need a subscription to access The Business Times app?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                " Yes, and no. Yes, if you wish to access all the articles from that day's newspaper on the app, and if you wish to read premium breaking news. You will also require a subscription to be able to read our in-depth news features and commentaries. But most breaking news stories are available for all to read. Subscribe online "
              }
              <a
                href="http://www.sphsubscription.com.sg/eshop/?r=products/newsubscriptionpackages&amp;pcode=BT&amp;utm_medium=online&amp;utm_source=helpmobileapp&amp;utm_campaign=btproductpage&amp;utm_term=tactical"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                here
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>Can I share articles from within the mobile apps?</strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                ' Yes, every story in the apps can be shared. Clicking on the "share" button at the top of the page will call up several options. When you choose any of these, you\'ll be sharing a link to the story on '
              }
              <a
                href="http://businesstimes.com.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                businesstimes.com.sg
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              How do I find back issues of The Business Times on my mobile app?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              Click on the calendar icon at the top right corner of the app.
              This should call up a calendar of archives. Subscribers have
              access to all breaking news and print edition stories from the
              past seven days. Select the date you wish to view. Slide the
              calendar panel to the right, to continue reading the news from
              your selected date. If you require stories dating back to more
              than seven days ago, please contact the SPH librarians at{" "}
              <a
                href="mailto:sphlib@sph.com.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                sphlib@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>What should I do if the app crashes?</strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              Please force-quit the app and then open it again. If you still
              have trouble, try turning your device off and then turning it back
              on. You can also try deleting and reinstalling the app, at no
              extra cost. If this does not work, please do let us know. You can
              call our customer service team at (65) 6388 3838 or email{" "}
              <a
                href="mailto:btocs@sph.com.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>{"When are BT's mobile apps updated?"}</strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                " The news in each day’s newspaper is available in our apps at 7.00am, Singapore time (2300 UTC). But the app is also updated throughout the day, with breaking news stories filed by BT’s reporters."
              }
            </li>
          </ul>

          <p>
            <strong>
              {
                "What should I do if the app does not seem to be updated and is still showing yesterday's news? "
              }
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              This happens occasionally when the app is not refreshed. Please
              force-quit the app, and then open it again. If you still have
              trouble, try turning your device off and then turning it back on.
              You can also try deleting and reinstalling the app, at no extra
              cost. If none of the above works, please do let us know. You can
              call our customer service team at (65) 6388 3838 or email{" "}
              <a
                href="mailto:btocs@sph.com.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              .
            </li>
          </ul>
          <p>
            <strong>
              How do I manage my BT subscriptions and auto-renewal on my
              iPad/iPhone?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                ' Go to the Apple App Store. Tap on the "Featured" icon, and scroll to the end of the page. Tap on your account name, which starts with "Apple ID", at the bottom left of the page. Choose "View Account" from the menu of options that pops up. You will need to enter your account\'s password. On the account screen, scroll down to the "Subscriptions" section and tap on "Manage App Subscriptions". You should then be able to view your existing subscription plans, including your BT subscription. You can turn auto-renewal on or off this way. '
              }
            </li>
          </ul>

          <p>
            <strong>
              Can I log into more than one device using one user ID?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                "It depends on which BT package you've subscribed to. If you use your BT account on multiple devices, do remember to log out after each session, to ensure that you are able to sign in successfully on the other devices. "
              }
            </li>
          </ul>

          <p>
            <strong>
              I am unable to log in on a second device, and have received an
              error message saying that I have exceeded the maximum number of
              devices on concurrent access, even though I do not think I have.
              Why is this so, and what should I do?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              This may be because you have not logged out of a device that you
              are no longer using, for example if you upgraded to a different
              smartphone or tablet. If you are about to change phones or
              tablets, please log out from the app. Deleting the app from the
              phone does not log you out from the app.
            </li>
            <li>
              {
                'To solve this problem, launch the app from one of your devices. Log in to your account, if you\'re not already logged in. Tap on "Log out". Tap "View account". You will see a list of devices that are currently logged into the BT app via your account. Remove any devices that are no longer in use. If you still encounter any problems, please call <strong>(65) 6388 3838</strong>, or email '
              }
              <a
                href="mailto:btocs@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              I would like to be alerted when there important breaking news
              updates on The Business Times app. How do I ensure that I will
              receive news prompts?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              For iPhone and iPad:
              <ul
                className="list-disc"
                style={{ paddingLeft: 30, marginBottom: 16 }}
              >
                <li>
                  {
                    ' You can turn on all notifications from BT\'s mobile apps by following these steps. Open your iPhone or iPad\'s "Settings" icon. Select "Notifications". Select "Business Times" from the list. Under "Allow Notifications", slide the slider on, i.e., to green instead of white. Choose how many notifications you want to receive under "Show in Notification Center" (ranging from "No Recent Items" up to "10 Recent Items"). If you want to be alerted to notifications by a sound, slide "Sounds" on. If you want to see how many unread notifications you have displayed as a number on the app icon, slide "Badge App Icon" on. If you want BT notifications to appear on your Lock Screen, slide "Show on Lock Screen" on. Finally, choose how you want notifications to appear outside of your Lock Screen.Selecting "Banners" will cause notifications to briefly appear at the top of your screen before fading away if not tapped on. "Alerts" will cause notifications to pop up in an alert box that won\'t disappear unless you dismiss it or view the story.'
                  }
                </li>
              </ul>
            </li>
            <li>
              &nbsp; For Android devices:
              <ul
                className="list-disc"
                style={{ paddingLeft: 30, marginBottom: 16 }}
              >
                <li>
                  {
                    'You can turn on all notifications from BT\'s Android app by following these steps. Open your Android app. From the Overflow (3-dot icon near the top right-hand corner) menu, select Settings. Select "Quiet Time", then check the boxes for "Enable Push", as well as "Enable Sounds" and/or "Enable Vibrate", depending on how you want to be alerted to notifications.'
                  }
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>
              {
                "To turn off notifications from The Business Times' mobile apps, you can follow these steps."
              }
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              For iPhone and iPad:
              <ul
                className="list-disc"
                style={{ paddingLeft: 30, marginBottom: 16 }}
              >
                <li>
                  {
                    'You can turn off all notifications from BT\'s mobile apps by following these steps. Open your iPhone or iPad\'s "Settings" icon. Select "Notifications". Select "Business Times" from the list. Under "Allow Notifications", slide the slider off, i.e., to white instead of green.'
                  }
                </li>
              </ul>
              For Android devices:
              <ul
                className="list-disc"
                style={{ paddingLeft: 30, marginBottom: 16 }}
              >
                <li>
                  {
                    'You can turn off all notifications from BT\'s Android app by following these steps. Open your Android app. From the Overflow (3-dot icon near the top right-hand corner) menu, select Settings. Select "Quiet Time", then ensure the boxes for "Enable Push", "Enable Sounds", and "Enable Vibrate" are unchecked.'
                  }
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>
              {
                "I am having problems downloading the e-paper/PDF version of today's newspaper. What should I do?"
              }
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                'Please try the following. Go to "Settings". Select "News & Cache", and then tap on "Clear Cache". Force quit your mobile app and launch it again. If this does not work, please do let us know. You can call us at (65) 6388 3838 or email '
              }
              <a
                href="mailto:btocs@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <hr className="my-4 border-t border-opacity-25" />

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            <a id="chapter4">Subscription matters</a>
          </h3>

          <p>
            <strong>How do I subscribe to The Business Times?</strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              You can subscribe{" "}
              <a
                href="http://www.sphsubscription.com.sg/eshop/index.php?r=site/ProductPage&amp;PCODE=BT&amp;utm_medium=online&amp;utm_source=help&amp;utm_campaign=btproductpage&amp;utm_term=tactical"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                online
              </a>
              , or by calling <strong>(65) 6388 3838</strong>.
            </li>
          </ul>

          <p>
            <strong>
              I subscribe to the print newspaper. Do I get free access to the
              website too?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                "You will be able to read some of the stories on our website. To read all the stories in full, however, please subscribe to either our All-in-One or online subscription plan. To read all the stories in full in BT's mobile apps, please subscribe to either our All-in-One, smartphones, or tablets plan. Details of BT's subscription plans can be found "
              }
              <a
                href="https://www.sphsubscription.com.sg/eshop/?r=products/newsubscriptionpackages&amp;pcode=bt&amp;utm_campaign=bt_subscription&amp;utm_medium=sph-publication&amp;utm_source=bt&amp;utm_content=subscribebutton-homepagefooter"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                here
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              I would like to add access to the website and/or the apps to my
              current newspaper subscription. How should I go about doing that?
            </strong>
          </p>
          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              Please contact our customer service team at{" "}
              <strong>(65) 6388 3838</strong>, or email{" "}
              <a
                href="mailto:btocs@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              . The phone line is manned from 8am-6pm on weekdays.
            </li>
          </ul>
          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            How can I suspend home delivery of the newspaper while on vacation?
          </h3>

          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              There are two ways to do this. Please give four working days
              notice for the suspension to take effect.
              <ol>
                <li>
                  Online: visit the{" "}
                  <a
                    className="text-verticals-btblue hover:text-black hover:underline"
                    href="http://www.sphsubscription.com.sg/eshop/?r=site/login&amp;return=myaccount/myservices"
                  >
                    SPH subscription site
                  </a>
                  {"to apply for a suspension. Here's the "}
                  <a
                    className="text-verticals-btblue hover:text-black hover:underline"
                    href="http://www.sphsubscription.com.sg/eshop/?r=site/tempstopfaq"
                  >
                    FAQ
                  </a>{" "}
                  for the process.
                </li>
                <li>
                  Call in to use our Interactive Voice Response system. Call{" "}
                  <strong>(65) 6388 3838</strong>. Press 1 for English, followed
                  by 1 for Newspapers, followed by 2 for temporary stop of
                  newspapers delivery.
                </li>
              </ol>
            </li>
          </ul>

          <p>
            <strong>
              {
                "I've subscribed to a plan online. However, I still can’t log in to read the stories. Why?"
              }
            </strong>
          </p>

          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                "If you purchased your subscription online, this is probably because your account hasn’t been activated yet. You can do so via a link that will be sent to the email address you provided."
              }
            </li>
          </ul>

          <p>
            If you require further assistance, please call our customer service
            team at <strong>(65) 6388 3838</strong>, or email{" "}
            <a
              className="text-verticals-btblue hover:text-black hover:underline"
              href="mailto:btocs@sph.com.sg"
            >
              btocs@sph.com.sg
            </a>
            . The phone line is manned from 8am-6pm on weekdays, Singapore time
            (UTC + 8hrs).
          </p>

          <hr className="my-4 border-t border-opacity-25" />
          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            <a id="chapter5">Newsletters, RSS, social media</a>
          </h3>

          <p>
            <strong>
              Does The Business Times offer any email newsletters that I can
              subscribe to? How do I sign up?
            </strong>
          </p>

          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              {
                "BT currently offers seven newsletters: Breakfast Brief, Daily Debrief, Today's Paper, Garage, Asean Business, SGSME Digest, and BT Lifestyle. Subscribe to the mailing list "
              }
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="http://www.businesstimes.com.sg/newsletter/sign-up"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              {
                "I've signed up for the newsletter. Why am I not receiving them?"
              }
            </strong>
          </p>

          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              Please check your junk folder in your email inbox. If you find
              that you still have not received the newsletter, please call our
              customer service team at <strong>(65) 6388 3838</strong>, or email{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="mailto:btocs@sph.com.sg"
              >
                btocs@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>
              Does BT have RSS feeds and how can I subscribe to these feeds?
            </strong>
          </p>

          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              You may choose from a full list of our{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="http://businesstimes.com.sg/rss-feeds"
              >
                RSS feeds here
              </a>
              .
            </li>
          </ul>

          <p>
            <strong>Is The Business Times on social media?</strong>
          </p>

          <ul
            className="list-disc"
            style={{ paddingLeft: 30, marginBottom: 16 }}
          >
            <li>
              For news updates, you can follow us on Twitter{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://twitter.com/BusinessTimes"
                target="_blank"
                rel="noreferrer"
              >
                @BusinessTimes
              </a>
              .
            </li>
            <li>
              If you would like to follow individual BT reporters,{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://twitter.com/BusinessTimes/lists/bt-newsdesk"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              is a list of all BT reporters with active Twitter accounts.
            </li>
            <li>
              BT is also on{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://www.facebook.com/thebusinesstimes"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              ,&nbsp;
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://www.linkedin.com/company/the-business-times"
              >
                LinkedIn
              </a>
              ,{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://www.instagram.com/businesstimessg/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>{" "}
              and{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://t.me/s/BizTimes"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>
              .
            </li>
            <li>
              You can also follow our Lifestyle team on Twitter{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://twitter.com/btlifestyle"
                target="_blank"
                rel="noreferrer"
              >
                @BTLifestyle
              </a>{" "}
              and{" "}
              <a
                className="text-verticals-btblue hover:text-black hover:underline"
                href="https://www.instagram.com/btlifestyle/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
              .
            </li>
          </ul>

          <hr className="my-4 border-t border-opacity-25" />
        </div>
      </div>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { Loading } from "@components/LoadMore/LoadMore";
import { Transition, TransitionChild } from "@headlessui/react";
import { useDelayedComponents } from "@hooks/useDelayedComponents";
import useSendPulseGaData from "@pages/Pulse/hooks/useSendPulseGaData";
import { cn } from "@util/helpers";

export default function PulseEntryButton(): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { isShown } = useDelayedComponents(3000);

  const { sendCustomEvent } = useSendPulseGaData();
  const location = useLocation();

  const handleNavigateToPulse = () => {
    setIsLoading(true);
    sessionStorage.setItem("pulse_back_path", location.pathname);
    sendCustomEvent("click", "pulse");
  };

  return (
    <div data-testid="pulse-entry-button-component">
      <Transition show={isShown}>
        <TransitionChild
          enter="transition duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative transition-all duration-300">
            <div className="flex w-full justify-end">
              <Link
                to={RouteFactory.pulse}
                reloadDocument
                className="group relative w-max cursor-pointer rounded-[30px] active:scale-90"
                onClick={handleNavigateToPulse}
              >
                <div
                  className={cn(
                    "absolute h-full w-full animate-pulseBoxShadow rounded-[30px] group-hover:[animation-play-state:paused]",
                    { "[animation-play-state:paused]": !isShown }
                  )}
                ></div>

                <div
                  className={cn(
                    "relative flex h-full w-max items-center justify-center overflow-hidden rounded-[30px] bg-gray-850 px-6 py-3 font-poppins text-3xs font-semibold tracking-2% text-white shadow-pulseEntryButton transition-all duration-700",
                    "before:absolute before:-top-12 before:left-0 before:h-36 before:w-10 before:-translate-x-20 before:-rotate-45 before:bg-white/40 before:delay-500 before:duration-700 hover:before:translate-x-40 hover:after:-right-4",
                    REMOVE_FOCUS_CSS
                  )}
                >
                  <Icon
                    type={IconTypeEnum.pulse}
                    stroke="white"
                    fill="none"
                    size={24}
                    viewBox="24"
                    className="mr-2"
                  />
                  Pulse
                </div>
              </Link>
            </div>
          </div>
        </TransitionChild>
      </Transition>

      <Loading isLoading={isLoading} />
    </div>
  );
}

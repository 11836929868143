import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { useDetectAdBlock } from "adblock-detect-react";

export default function AboutUsPage(): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full" data-testid="about-us-component">
      <AdSettings
        adNames={["lb1", "catfish", "abm"]}
        path={RouteFactory.aboutUs}
      />

      <MetaTags
        title="About Us"
        description="Read more at The Business Times."
        slug={RouteFactory.aboutUs}
      />

      <GAData
        title="About_Us"
        level2="miscellaneous"
        contentcat={1}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container rootClassName="py-6 font-poppins">
        <p>
          {
            "Singapore's only financial daily, The Business Times (BT), is the authoritative guide on local, regional and international business markets. In addition to corporate news and "
          }
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/opinion"
          >
            commentary
          </a>
          {", BT covers the "}
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/garage"
            target="_blank"
            rel="noreferrer"
          >
            startup
          </a>
          {", "}
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/wealth"
            target="_blank"
            rel="noreferrer"
          >
            wealth
          </a>
          {", "}
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/real-estate"
            target="_blank"
            rel="noreferrer"
          >
            property
          </a>
          ,
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/lifestyle"
          >
            lifestyle
          </a>
          {" and "}
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/sme"
            target="_blank"
            rel="noreferrer"
          >
            SME
          </a>
          {" scenes, and tells you what you need to know about "}
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/asean-business"
            target="_blank"
            rel="noreferrer"
          >
            Asean
          </a>
          {" and the "}
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="https://www.businesstimes.com.sg/global-enterprise"
            target="_blank"
            rel="noreferrer"
          >
            world
          </a>
          . It also has a young audience initiative known as
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            data-saferedirecturl="https://www.google.com/url?q=https://www.businesstimes.com.sg/thrive&amp;source=gmail&amp;ust=1675265004461000&amp;usg=AOvVaw0XtIVVf2CjTgU8yMi_BEXt"
            href="https://www.businesstimes.com.sg/thrive"
            target="_blank"
            rel="noreferrer"
          >
            Thrive
          </a>
          . BT is part of SPH Media.
        </p>

        <div className="w-full lg:w-7/12">
          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            The Editors
          </h3>

          <AboutUsTable />
        </div>

        <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
          Business Awards
        </h3>

        <p>
          BT organises the following annual business awards events in
          conjunction with its partners.
        </p>

        <p>
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="http://www.sbawards.com.sg/"
          >
            <strong>The Singapore Business Awards</strong>
          </a>
          {
            " was launched in 1985, with the Businessman of the Year Award. In 1986, the Enterprise Award was introduced to recognise small and medium-sized enterprises. In 1990, the Outstanding Manager Award was introduced to recognise professional managers. This was renamed the Oustanding Chief Executive of the Year Award in 1995. In 2005, the Oustanding Chief/Senior Executive (Overseas) Award was introduced, to recognise Singaporean CEOs working abroad. Winners of the four awards, known collectively as the Singapore Business Awards, are decided by a panel of judges drawn from the business sector, official agencies and academia."
          }
        </p>

        <p>
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="http://www.scawards.com.sg/"
          >
            <strong>The Singapore Corporate Awards</strong>
          </a>
          {
            " recognises excellence in corporate governance, with five awards: Best Managed Board, Best Chief Executive Officer, Best Chief Financial Officer Award, Best Investor Relations Award and Best Annual Report Award."
          }
        </p>

        <p>
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="http://www.enterprise50.org/"
          >
            <strong>The Enterprise 50 (E50) Awards</strong>
          </a>
          {
            " was launched in 1995 to recognise local, privately-held companies who have contributed to economic development in Singapore and abroad."
          }
        </p>

        <p>
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="http://www.eeaward.com/"
          >
            <strong>The Emerging Enterprise Award</strong>
          </a>
          {
            " was first launched in 2008 to recognise outstanding small and medium enterprises (SMEs) in Singapore. Winners receives prizes to support their growth and development, such as interest-free loans and professional services and training programmes."
          }
        </p>

        <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
          {"Community & Philanthropy"}
        </h3>

        <p>
          <a
            className="text-verticals-btblue hover:text-black hover:underline"
            href="http://baf.sg/"
          >
            <strong>{"The Business Times Budding Artists' Fund"}</strong>
          </a>
        </p>

        <p>
          {
            "In 2005, BT adopted the Budding Artists' Fund, an initiative of The Old Parliament House (2002-2014). The fund was started with a conviction that no child should be denied the opportunity to pursue a training in the arts due to a lack of financial resources. Since 2005, BT BAF has reached out to more than 9,000 beneficiaries through a variety of programmes comprising of art camps, workshops, signature events and fundraising initiatives. Beneficiaries of BT BAF receive a structured arts training at The Little Arts Academy."
          }
        </p>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

export function AboutUsTable(): React.ReactElement {
  const editors = [
    { name: "Chen Huifen", position: "Editor" },
    { name: "Chan Cheow Pong", position: "Associate Editor (Audience)" },
    { name: "Christopher Lim", position: "Associate Editor (Product)" },
    { name: "Lilian Ang", position: "Associate Editor (Revenue)" },
    { name: "Joan Ng", position: "News Editor" },
    { name: "Dennis Chan", position: "Night Editor" },
    { name: "Vivien Ang", position: "Chief Sub-editor" },
    { name: "Lee U-Wen", position: "Deputy News Editor (Regional)" },
    { name: "Kenneth Lim", position: "Deputy News Editor (ESG)" },
    {
      name: "Michelle Low",
      position: "Deputy News Editor (Startups & Property)",
    },
    { name: "Janice Heng", position: "Deputy News Editor (Macro & SME)" },
    { name: "Naveen Verghese", position: "Deputy Night Editor" },
    { name: "Dhany Osman", position: "Deputy Audience Editor" },
    { name: "Vivien Shiao", position: "Young Audience Editor" },
    { name: "Alvina Soh", position: "Social Media Editor" },
    { name: "Lee Kim Siang", position: "Production Editor" },
    { name: "Claressa Monteiro", position: "Podcast Editor" },
    { name: "Ang Guangzheng", position: "Video Editor" },
    { name: "Dylan Tan", position: "Membership Editor" },
    { name: "Edmund Loh", position: "Web Editor" },
    { name: "Jeanette Tan", position: "Deputy Web Editor" },
    { name: "Anna Teo", position: "Opinion Editor" },
    { name: "Jaime Ee", position: "Lifestyle Editor" },
    { name: "Genevieve Cua", position: "Wealth Editor" },
    { name: "Simon Ang", position: "Visual Editor" },
    { name: "Charmaine Martin", position: "Deputy Visual Editor" },
  ];

  return (
    <table className="mb-5 w-full table-auto">
      <tbody>
        {editors?.map((row, index: number) => {
          return (
            <tr className="border-b" key={index}>
              <td className="p-2">
                <em>{row.name}</em>
              </td>
              <td className="p-2">{row.position}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

import { fetchShortUrl } from "@app/pages/Article/Article.server";
import { ENVIRONMENT } from "@util/constant";
import { getEnvironmentDomain } from "@util/helpers";
import axios from "axios";

export const useFetchNextArticle = async ({
  sectionUniqueName,
  search_after,
}: {
  sectionUniqueName: string;
  search_after: (number | string)[];
}) => {
  try {
    const response = await axios.post("/_plat/api/v1/next-article", {
      category: sectionUniqueName,
      search_after,
    });

    const nextStory = response.data;
    if (typeof nextStory.context.urlPath === "undefined") return;
    const longURL = `${getEnvironmentDomain(ENVIRONMENT)}${nextStory.context.urlPath}`;
    const shortUrl = await fetchShortUrl(longURL);
    const nextStoryWShortUrl = {
      ...nextStory,
      shortUrl: shortUrl || longURL,
    };

    return nextStoryWShortUrl;
  } catch (error) {
    return [];
  }
};

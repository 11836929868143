import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getKeywordsToDisplayItems } from "@pages/Article/utils/helpers";
import { Tag } from "@typings/Cue";

export const getBaseUrl = () => {
  const DEFAULT_URL = "http://localhost:8888";
  const url = process.env.URL_TEST ?? DEFAULT_URL;

  return url;
};

/**
 * Description: Return a boolean value to show or hide the listing page sticky header
 * @returns html element(ref) and boolean
 */
export const useBreadCrumbState = () => {
  // Handles state for nav bar variation when header sticks to top of window
  const [isShowBreadCrumb, setIsShowBreadCrumb] = useState(false);

  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const breadcrumbs = breadcrumbsRef.current;

    if (!breadcrumbs) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsShowBreadCrumb(e ? e.intersectionRatio < 1 : false);
      },
      { threshold: [1] }
    );

    observer.observe(breadcrumbs);
    return () => {
      observer.unobserve(breadcrumbs);
    };
  }, []);
  return { isShowBreadCrumb, breadcrumbsRef };
};

/**
 * Description: Return a boolean value to show or hide the Article level sticky header
 * @returns html element(ref) and boolean
 */
export const useArticleHeaderState = () => {
  // Handles state for nav bar variation when header sticks to top of window
  const [isShowArticleHeader, setIsShowArticleHeader] = useState(false);

  const articleTopRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const beforeFirstArticleRef = articleTopRef.current;

    if (!beforeFirstArticleRef) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsShowArticleHeader(e ? e.intersectionRatio < 1 : false);
      },
      { threshold: [1] }
    );

    observer.observe(beforeFirstArticleRef);
    return () => {
      observer.unobserve(beforeFirstArticleRef);
    };
  }, []);
  return { isShowArticleHeader, articleTopRef };
};

/**
 * Description: Return a boolean value to show or hide the sticky header
 * @returns html element(ref) and boolean
 */
export const useHeaderState = () => {
  // Handles state for nav bar variation when header sticks to top of window
  const headerRef = useRef<HTMLHeadElement>(null);
  const [isHeaderStuck, setIsHeaderStuck] = useState(false);
  const [headerWidth, setHeaderWidth] = useState(0);

  // Handles state for lower nav bar scrolling in and out on mobile/tablet view
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showLowerNavBar, setShowLowerNavBar] = useState(true);

  const setHeaderDimension = () => {
    if (!headerRef.current) return;
    setHeaderWidth(headerRef.current.clientWidth);
  };

  useEffect(() => {
    const header = headerRef.current;

    if (!header) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsHeaderStuck(e ? e.intersectionRatio < 1 : false);
      },
      { threshold: [1] }
    );

    observer.observe(header);
    return () => {
      observer.unobserve(header);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setShowLowerNavBar(
        currentScrollPos < 260 || prevScrollPos > currentScrollPos
      );

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    // Add mutation observer to detect changes if there a change in app width and set the dimension of the header
    if (typeof window === "undefined") return;

    const appElement = window.document.querySelector("#app");

    if (!appElement) return;
    const observer = new MutationObserver(setHeaderDimension);
    observer.observe(appElement, {
      attributes: true,
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    // Add event listener to detect changes if there a change in window width and set the dimension of the header
    if (typeof window === "undefined") return;
    if (window.innerWidth === 0) return;

    window.addEventListener("resize", setHeaderDimension);
    setHeaderDimension();

    return () => {
      window.removeEventListener("resize", setHeaderDimension);
    };
  }, []);

  return { showLowerNavBar, isHeaderStuck, headerRef, headerWidth };
};

export const useCategoriesFromPath = () => {
  const { pathname } = useLocation();

  let parentCategory: string;
  let childCategory: string;
  const pathNameSplit = pathname.split("/");

  if (pathname.startsWith("http")) {
    parentCategory = pathNameSplit[3];
    childCategory = pathNameSplit[4];
  } else {
    parentCategory = pathNameSplit[1];
    childCategory = pathNameSplit[2];
  }

  return { parentCategory, childCategory };
};

export const getSectionNavigationPath = (tags: Tag[], parentPath: string) => {
  const displayKeywordsSectionNavigation = getKeywordsToDisplayItems(tags);

  if (displayKeywordsSectionNavigation) {
    return displayKeywordsSectionNavigation.urlPath.substring(1);
  }

  return parentPath;
};

export const BRIGHTCOVE_ACCOUNT_ID = 4802324426001;
export const BRIGHTCOVE_FEATURED_PLAYLIST_ID = 5232634749001; // https://studio.brightcove.com/products/videocloud/media/playlists/5232634749001
export const BRIGHTCOVE_ALL_PLAYLIST_ID = 5313590812001; // https://studio.brightcove.com/products/videocloud/media/playlists/5313590812001
export const BRIGHTCOVE_BUDGET_2024_PLAYLIST_ID = "1782156331147255113"; // https://studio.brightcove.com/products/videocloud/media/playlists/1782156331147255113
export const BRIGHTCOVE_BUDGET_2025_PLAYLIST_ID = "1819466271886801438"; // https://studio.brightcove.com/products/videocloud/media/playlists/1819466271886801438
export const BRIGHTCOVEOAUTHAPI =
  "https://oauth.brightcove.com/v4/access_token?grant_type=client_credentials";
export const BRIGHTCOVECMSAPI = `https://cms.api.brightcove.com/v1/accounts/${BRIGHTCOVE_ACCOUNT_ID}`;
export const BRIGHTCOVE_PLAYERID = "Nkg3unHRx";
export const BRIGHTCOVE_PLAYER_API = "https://players.brightcove.net/";

import { HTMLAttributes, useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import LoadMore from "@components/LoadMore/LoadMore";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import { BUDGET2025_PAGE_SIZE } from "@pages/SingaporeBudget/2025/utils/constants";
import { getKickerFromArticleDataObject } from "@util/helpers";

export type RelatedStoriesProps = {
  articles: SanitizedSectionArticle[];
  testId?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function RelatedStories({
  testId,
  className,
  articles,
}: RelatedStoriesProps): React.ReactElement {
  const [moreArticles, setMoreArticles] =
    useState<SanitizedSectionArticle[]>(articles);
  const [hasMoreStories, setHasMoreStories] = useState(true);

  const variant: CardVariations = {
    image: {
      position: "right",
      width: "w-1/4",
      extraClass: "order-1 lg:order-2",
    },
    content: {
      width: "w-3/4",
      extraClass: "pl-4 lg:pr-4 lg:pl-0",
    },
    title: {
      color: "text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  const handleLoadMore = async () => {
    const page = moreArticles.length / BUDGET2025_PAGE_SIZE + 1;

    const newArticles = await fetchMoreStoriesData(
      "singapore-budget-2025",
      "keywords",
      page
    );

    setMoreArticles([...moreArticles, ...newArticles]);

    if (newArticles.length < BUDGET2025_PAGE_SIZE) {
      setHasMoreStories(false);
    }
  };

  return (
    <>
      {moreArticles.length > 0 ? (
        <div className={className} data-testid={testId}>
          <BlockTitle text="RELATED STORIES" />

          <div className="divide-y border-gray-175">
            {moreArticles.map((article) => {
              const {
                id,
                title,
                kicker,
                slug,
                media,
                updated,
                edited,
                sections,
                displaySetting,
                blurb,
              } = article;
              const sectionUniqueName = sections?.[0]?.uniqueName;

              return (
                <BasicCard
                  key={id}
                  id={id}
                  title={title}
                  slug={slug}
                  media={media}
                  blurb={blurb}
                  kicker={
                    kicker?.fields?.[0]?.value ||
                    getKickerFromArticleDataObject(article)
                  }
                  variations={variant}
                  rootClassName="py-4"
                  updated={updated}
                  edited={edited}
                  hasUpdatedTimestampDisplay={
                    displaySetting?.hasUpdatedTimeDisplayed
                  }
                  {...(sectionUniqueName
                    ? {
                        defaultImage: {
                          directoryName: sectionUniqueName,
                        },
                      }
                    : undefined)}
                />
              );
            })}
          </div>

          {moreArticles.length >= BUDGET2025_PAGE_SIZE ? (
            <LoadMore
              rootClassName="mt-4"
              onLoadMore={handleLoadMore}
              hasMore={hasMoreStories}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}

/**
 * Company: SPHMedia
 * Description: Asean Business Vertical Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { VerticalProps } from "@app/types/Verticals";
import { getSectionJSONLD } from "@app/util/helpers";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import KeywordStoriesRow from "@pages/Section/components/VerticalMeta/KeywordStoriesRow";
import LatestStoriesRow from "@pages/Section/components/VerticalMeta/LatestStoriesRow";
import TopStoriesRow from "@pages/Section/components/VerticalMeta/TopStoriesRow";

import AseanBanner from "./components/AseanBanner";
import AseanNewsletterDescription from "./components/AseanNewsletterDescription";

export default function AseanBusinessVertical({
  data: { topStories, additional, title, entity },
  mostReadArticles,
}: VerticalProps): ReactElement {
  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.aseanBusiness}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="THE BUSINESS TIMES ASEAN Business - Find ASEAN Business News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.aseanBusiness}
        jsonLd={getSectionJSONLD(entity)}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="mt-6 px-3">
        <AseanBanner />

        <TopStoriesRow articles={topStories} verticalName="asean" />

        <LatestStoriesRow
          articles={topStories.slice(4, 9)}
          seeMoreLink="/international/asean/latest"
          verticalName="asean"
          mostReadStories={mostReadArticles}
        />

        <KeywordStoriesRow
          seeMoreLink="/keywords/asean"
          data={additional?.[0]}
          verticalName="asean"
          verticalBlockTitle="ASEAN"
        />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<AseanNewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) => newsletter.type === NEWSLETTER_TYPE.ASEAN
        )}
        btnColors="bg-verticals-asean text-white hover:bg-white hover:text-verticals-asean"
        linkColors="text-verticals-asean"
      />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

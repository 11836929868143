import type { Params } from "react-router-dom";
import { RouteFactory, routesVerticals } from "@app/routePaths";
import {
  Author,
  ContributorProfile,
  Element,
  Others,
  Section,
  Storyline,
  StoryThread,
  Tag,
} from "@app/types/Cue";
import { ArticleDisplayType, KickerEnums } from "@app/types/enums";
import { getAuthorProfile } from "@components/Byline/BylineHelper";
import CompactViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayCompact";
import InfographicsViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayInfographics";
import LifestyleViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayLifestyle";
import MainNewsDefaultViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayMainNewsDefault";
import VerticalNewsDefaultViewMode from "@pages/Article/components/ArticleDisplay/ArticleDisplayVerticalsNewsDefault";
import AseanBanner from "@pages/Section/layouts/Verticals/AseanBusiness/components/AseanBanner";
import GlobalEnterpriseBanner from "@pages/Section/layouts/Verticals/GlobalEnterprise/components/GlobalEnterpriseBanner";
import SMEBanner from "@pages/Section/layouts/Verticals/SME/components/SMEBanner";
import { textToSlug } from "@util/helpers";
import { isEmpty } from "lodash-es";

import {
  ELEMENTS_TYPE_TO_EXCLUDE,
  KEYWORDS_TO_DISPLAY_MORE_SECTION,
} from "./constants";

export const ArticleFactory = ({
  displayType = ArticleDisplayType.MainNewsDefault,
}: Params) => {
  switch (displayType) {
    case ArticleDisplayType.Compact:
      return CompactViewMode;

    case ArticleDisplayType.Infographics:
      return InfographicsViewMode;

    case ArticleDisplayType.Lifestyle:
      return LifestyleViewMode;

    case ArticleDisplayType.VerticalsNewsDefault:
      return VerticalNewsDefaultViewMode;

    case ArticleDisplayType.MainNewsDefault:
    default:
      return MainNewsDefaultViewMode;
  }
};

export const VerticalHeaderFactory = ({
  verticalPath,
}: {
  verticalPath: string;
}) => {
  switch (verticalPath) {
    case RouteFactory.globalEnterprise:
      return GlobalEnterpriseBanner;

    case RouteFactory.aseanBusiness:
      return AseanBanner;

    case RouteFactory.sgsme:
      return SMEBanner;
  }
};

/**
 * Checks if paywall should be displayed on Articles
 * Examples of articles shown on Article.test.tsx
 * @param {Object} props
 * @param {boolean} props.isGiftReceived If article was received as a gift
 * @param {string} props.variant "default" || "full"
 * @param {string} props.contentAccess "0" || "1"
 * @returns {boolean}
 */
export const checkDisplayPaywall = ({
  isGiftReceived,
  variant,
  contentAccess,
}: {
  isGiftReceived: boolean;
  variant: string | undefined;
  contentAccess: string;
}): boolean => {
  if (isGiftReceived || variant === "full") return false;
  return contentAccess === "1";
};

/**
 * Transpose the article elements and retrieve the annotations and elements values.
 * @param storyLine The storyline of the article.
 * @param elements The full list of elements in the article.
 * @param isSubscriber The user type.
 * @param isPremium The article content access.
 * @returns {Element[]}
 */
export const getResolvedArticleElements = (
  storyLine: Storyline = [],
  elements: Element[],
  isPremium: boolean = false,
  isSubscriber: boolean = false,
  isGifted: boolean = false,
  isPreview: boolean = false
): Element[] => {
  // Get the list of elements based on storyline
  const elementsWithAnnotationRef = getArticleElementsWithAnnotationReference(
    storyLine,
    elements
  );

  // Remove elements that are not needed
  const elementsWithoutExcludedType = elementsWithAnnotationRef.filter(
    ({ type }) => !ELEMENTS_TYPE_TO_EXCLUDE.includes(type.toString())
  );

  // Add reference to elements that has children
  const elementsWithListRef = elementsWithoutExcludedType.map((element) => {
    const reference = element.children
      ?.map((child) => elements.find((element) => child === element.id))
      .filter((element): element is Element => !!element);

    if (typeof reference === "undefined" || isEmpty(reference)) return element;

    const annotationWithRef = getAnnotationReference(reference, elements);

    return { ...element, reference: annotationWithRef };
  });

  // Logical gate to check if article will be displayed in full
  const fullList = isGifted || isSubscriber || !isPremium || isPreview;

  // Return full list of elements.
  if (fullList) return elementsWithListRef;

  // Return first five elements.
  return elementsWithListRef.slice(0, 5);
};

/**
 * Retrieves the annotation reference for each element in the given array of elements.
 *
 * @param elements - The array of elements to retrieve the annotation reference for.
 * @param elementSource - The array of element sources to search for the annotation reference.
 * @returns An array of elements with their corresponding annotation references.
 */
const getAnnotationReference = (
  elements: Element[],
  elementSource: Element[]
): Element[] => {
  const annotationWithRef = elements.map((element) => {
    const fields = element.fields.map((field) => {
      const annotations = field?.annotations?.map((annotation) => {
        const element = elementSource.find((element) => {
          return element.id === annotation.value;
        });

        if (element) {
          return {
            ...annotation,
            reference: element,
          };
        }

        return annotation;
      });

      if (typeof annotations === "undefined" || isEmpty(annotations))
        return field;

      return { ...field, annotations };
    });

    return { ...element, fields };
  });

  return annotationWithRef;
};

/**
 * Retrieves the list of article elements with annotation references based on the storyline and elements.
 * @param {Storyline} storyLine The storyline of the article.
 * @param {Element[]} elements The list of all elements in the article.
 * @returns {Element[]} The list of article elements with annotation references.
 */
export const getArticleElementsWithAnnotationReference = (
  storyLine: Storyline = [],
  elements: Element[]
): Element[] => {
  const articleElements: Element[] = storyLine
    .map((id) => elements.find((element) => element.id === id)) // Get the list of elements based on storyline
    .filter((element): element is Element => !!element); // Remove undefined elements

  const articleElementsWithAnnotationsRef = getAnnotationReference(
    articleElements,
    elements
  );

  return articleElementsWithAnnotationsRef;
};

/**
 * Check if it a branded content
 *
 * @param kicker
 */
export const getIsBrandedContent = (kicker: string) => {
  return (
    kicker.toUpperCase().replace(/\s/g, "") === KickerEnums.BRANDED_CONTENT
  );
};

/**
 * Check if the array of keywords contains the keyword to query more article based on keywords
 *
 * @param Tag
 */
export const getKeywordsToDisplayItems = (keywords: Tag[]) => {
  const key = keywords.find((keyword) => {
    return KEYWORDS_TO_DISPLAY_MORE_SECTION.includes(keyword.urlPath)
      ? keyword
      : null;
  });

  return key;
};

/**
 * Get the dependent paths of the sections
 *
 * @param sections
 * @returns {string[]}
 */
export const getSectionDependentPaths = (sections?: Section[]): string[] => {
  if (!sections) return [];

  const paths = sections.reduce<string[]>((previousValue, currentValue) => {
    const path = `/${currentValue.uniqueName.replace("_", "/")}`;

    if (routesVerticals.includes(path)) {
      return [...previousValue, path, `${path}/latest`];
    }

    return [...previousValue, path];
  }, []);

  return paths;
};

/**
 * Check if imu should be displayed after 3rd paragraph
 *
 * @param isSubscriber - Whether the user is a subscriber
 * @param totalParagraphs - The total number of paragraphs in the article
 * @param isPremium - Whether the article is premium
 * @returns {boolean}
 */
export const display3rdParagraphImu = (
  isSubscriber: boolean = false,
  isPremium: boolean = false
): boolean => {
  // Always display imu after 3rd paragraph if user is subscriber.
  if (isSubscriber) return true;

  // Always display imu after 3rd paragraph if article is not premium.
  if (!isPremium) return true;

  // Hide imu after 3rd paragraph if totalParagraphs is 5 and user is not premium.
  return false;
};

/*
 * Get the dependent paths of the keywords
 *
 * @param tags
 * @returns {string[]}
 */
export const getKeywordsDependentPaths = (keywords: Tag[]): string[] => {
  if (!keywords) return [];

  const paths = keywords.map((keyword) => keyword.urlPath);

  return paths;
};

/**
 * Helper function to get the dependent paths of articles from its author(s).
 *
 * @param authors List of authors from article.
 * @returns {string[]}
 */
export const getPathsFromAuthors = (authors: Author[] = []): string[] => {
  const paths = authors
    .map((author) => {
      const { currentAuthorProfile } = getAuthorProfile(author.profiles);
      return currentAuthorProfile?.content.urlPath;
    })
    .filter((path): path is string => {
      return !!path;
    });

  return paths;
};

/**
 * Helper function to get the dependent paths of articles from its contributor profile(s).
 *
 * @param contributorProfiles List of contributor profiles.
 * @returns {string[]}
 */
export const getPathsFromContributors = (
  contributorProfiles: ContributorProfile[]
): string[] => {
  const paths = contributorProfiles
    .map((profile) => {
      return profile.content.urlPath;
    })
    .filter((path): path is string => {
      return !!path;
    });

  return paths;
};

/**
 * Helper function to get the dependent paths of articles from story threads.
 *
 * @param others The others object from the article.
 * @returns {string[]}
 */
export const getPathsFromStoryThreads = (others?: Others): string[] => {
  const storyThread = others?.storyThread;

  if (typeof storyThread == "undefined") return [];

  try {
    const storyThreadData: StoryThread[] = JSON.parse(storyThread);
    const storyThreadObject = storyThreadData.at(0);

    if (typeof storyThreadObject === "undefined") return [];

    const path = textToSlug(storyThreadObject.value);

    return [`/${path}`];
  } catch (error) {
    return [];
  }
};

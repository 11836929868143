import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { FilteringByDate } from "@pages/Search/contants";
import {
  FilteringfacetedObjectEnum,
  SearchIndividualFilteringProps,
} from "@pages/Search/types";

import SearchIndividualModal from "./SearchIndividualAdvanceModal";
import SearchIndividualPopover from "./SearchIndividualAdvancePopover";

export default function SearchIndividualAdvanceFiltering({
  advanceFilteringDisplayObj,
  facetedSearchData,
  setSelectedFacetedObj,
  selectedFacetedData,
}: SearchIndividualFilteringProps): React.ReactElement {
  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    setSelectedFacetedObj(facetedKey, facetedValue);
  };

  return (
    <div
      className="mr-6 max-w-full lg:mr-8"
      data-testid="search-individual-advance-filtering-component"
    >
      {selectedFacetedData && selectedFacetedData.facetedValue !== "" ? (
        <div className="flex w-full max-w-[50vw] border border-gray-175 bg-white px-3 lg:max-w-[600px] lg:border-gray-250 lg:bg-gray-250">
          <p className="m-0 line-clamp-1 font-poppins text-xs font-semibold leading-[3]">
            {FilteringByDate[selectedFacetedData.facetedValue] ||
              selectedFacetedData.facetedValue}
          </p>

          <button
            onClick={() => {
              selectedFacetedData &&
                handleSelectedFaceted(selectedFacetedData.facetedKey, "");
            }}
          >
            <Icon type={IconTypeEnum.close} fill="#2b2b2b" size={24} />
          </button>
        </div>
      ) : null}

      {!selectedFacetedData || selectedFacetedData.facetedValue === "" ? (
        <>
          <div className="hidden lg:block">
            <SearchIndividualPopover
              advanceFilteringDisplayObj={advanceFilteringDisplayObj}
              facetedSearchData={facetedSearchData}
              setSelectedFacetedObj={(
                facetedKey: FilteringfacetedObjectEnum,
                facetedValue: string
              ) => {
                handleSelectedFaceted(facetedKey, facetedValue);
              }}
            />
          </div>

          <div className="block lg:hidden">
            <SearchIndividualModal
              advanceFilteringDisplayObj={advanceFilteringDisplayObj}
              facetedSearchData={facetedSearchData}
              setSelectedFacetedObj={(
                facetedKey: FilteringfacetedObjectEnum,
                facetedValue: string
              ) => {
                handleSelectedFaceted(facetedKey, facetedValue);
              }}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

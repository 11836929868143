import { isNumber, toNumber } from "lodash-es";

export const RouteFactory = {
  home: "/",
  myBT: "/mybt",
  myBTOnboard: "/mybt/onboard",
  myBTIntro: "/mybt/intro",
  budget2024: "/singapore-budget-2024",
  keywordsBudget2024: "/keywords/singapore-budget-2024",
  keywordsBudget2025: "/keywords/singapore-budget-2025",
  article: (id: string) =>
    isNumber(toNumber(id)) ? `/article/${id}` : `/${id}`,
  section: (path: string) => `/${path}`,
  keywords: (keyword: string) => `/keywords/${keyword}`,
  search: "/search",
  authors: (uri: string) => `/authors/${uri}`,
  breakingNews: "/breaking-news",
  aboutUs: "/content/about-us",
  contactUs: "/content/contact-us",
  help: "/help",
  wealth: "/wealth",
  globalEnterprise: "/international/global",
  aseanBusiness: "/international/asean",
  newsletterSignup: "/newsletter/sign-up",
  newsletterBigMoneyArchivePage: "/newsletter/big-money",
  newsletterGarageArchivePage: "/newsletter/garage",
  newsletterThriveArchivePage: "/newsletter/thrive",
  thrive: "/thrive",
  thrivePastIssues: "/thrive/past-issues",
  newsletterPropertyInsightArchivePage: "/newsletter/property",
  newsletterAseanBusinessArchivePage: "/newsletter/asean",
  newsletterEsgInsightsArchivePage: "/newsletter/esg",
  thriveEvents: "/thrive/events",
  thriveAboutUs: "/thrive/about-us",
  sgsme: "/singapore/smes",
  lifestyle: "/lifestyle",
  lifestyleLuxe: "/lifestyle/bt-luxe",
  podcasts: "/podcasts",
  advertise: "/advertise",
  podcastsMoneyHacks: "/keywords/money-hacks",
  podcastsMarkMarket: "/keywords/mark-to-market",
  podcastsWealthBT: "/keywords/wealthbt",
  podcastsPropertyBT: "/keywords/propertybt",
  podcastsMarketFocus: "/keywords/market-focus",
  podcastsBranded: "/keywords/branded-podcasts",
  podcastsLensOn: "/keywords/lens-on",
  podcastsBTCorrespondents: "/keywords/bt-correspondents",
  faq: "/pr/faq",
  esg: "/esg",
  rssFeeds: "/rss-feeds",
  videos: "/videos",
  videosDetailsPage: (slug: string, id: string) => `/videos/${slug}/${id}`,
  hub: "/hub",
  mbo: "/bt-mbo",
  paidPressRelease: "/paid-press-release",
  pulse: "/pulse",
  pulseSlide: (articleId: string) => `/pulse/article/${articleId}`,
  newsletterUnsubscribe: "/bt_newsletter/unsubscribe",
  newsletterUnsubscribeSuccess: "/unsubscription",
};

export const isRouteCSROnly = function isRouteCSROnly(path: string): boolean {
  const staticRoutesRegexList = [new RegExp(`^.*${RouteFactory.search}.*`)];

  return staticRoutesRegexList.some((regex: RegExp) => regex.test(path));
};

export const routesVerticals = [
  RouteFactory.globalEnterprise,
  RouteFactory.aseanBusiness,
  RouteFactory.sgsme,
];

export const routesToExcludeFromSection = [
  RouteFactory.keywords,
  RouteFactory.wealth,
  RouteFactory.lifestyle,
  RouteFactory.lifestyleLuxe,
  ...routesVerticals,
  RouteFactory.hub,
];

export const routesPodcastsKeywords = [
  RouteFactory.podcastsMoneyHacks,
  RouteFactory.podcastsMarkMarket,
  RouteFactory.podcastsWealthBT,
  RouteFactory.podcastsPropertyBT,
  RouteFactory.podcastsMarketFocus,
  RouteFactory.podcastsBranded,
  RouteFactory.podcastsLensOn,
  RouteFactory.podcastsBTCorrespondents,
];

export enum IconTypeEnum {
  facebook = "facebook",
  telegram = "telegram",
  email = "email",
  linkedin = "linkedin",
  twitter = "twitter",
  whatsapp = "whatsapp",
  instagram = "instagram",
  profile = "profile",
  search = "search",
  hamburgerForDesktop = "hamburgerForDesktop",
  hamburgerForMobile = "hamburgerForMobile",
  gift = "gift",
  closeWhite = "closeWhite",
  share = "share",
  link = "link",
  pulse = "pulse",
  close = "close",
  xMark = "xMark",
}

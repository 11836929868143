import { useState } from "react";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import axios from "axios";

export const useMoreVideos = (videos: BrightcoveVideo[]) => {
  const [moreVideos, setMoreVideos] = useState<BrightcoveVideo[]>(videos);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);

  const handleLoadMoreVideos = async () => {
    const response = await axios.post<BrightcoveVideo[]>(
      "/_plat/api/v1/more-videos",
      { offset: moreVideos.length }
    );

    const _moreVideos = response.data || [];

    if (_moreVideos.length < 10) setHasMoreVideos(false);

    setMoreVideos((prev) => [...prev, ..._moreVideos]);
  };

  return { moreVideos, hasMoreVideos, handleLoadMoreVideos };
};

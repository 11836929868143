import { RouteFactory } from "@app/routePaths";
import GAData from "@components/GAData/GAData";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import ThriveFavicon from "@pages/Thrive/components/ThriveFavicon";
import ThriveFooter from "@pages/Thrive/components/ThriveFooter";
import ThriveHeader from "@pages/Thrive/components/ThriveHeader";
import { thriveEventsPageMetaTags } from "@pages/Thrive/constants";

import ThriveBanner from "./components/ThriveBanner";
import ThrivePastEvents from "./components/ThrivePastEvents";

export default function ThriveEvents(): React.ReactElement {
  const MetaTagsProps = thriveEventsPageMetaTags;

  const title = renderPageTitleV2({
    kind: "custom",
    title:
      "Events, thrive - The Business Times | Career, Money and Life for Gen Zs in Singapore",
  });

  return (
    <div className="w-full bg-verticals-thrive" id="thrive-events-page">
      <ThriveFavicon />

      <MetaTags
        title={title}
        ogSiteName={title}
        description={MetaTagsProps.description}
        ogDescription={MetaTagsProps.description}
        ogImageAlt={MetaTagsProps.description}
        ogImageUrl={MetaTagsProps.ogImageUrl}
        slug={RouteFactory.thriveEvents}
      />

      <GAData title="Thrive_Events" level2="miscellaneous" />

      <ThriveHeader />

      <ThriveBanner />

      <ThrivePastEvents />

      <div className="flex justify-center bg-gray-850 font-poppins">
        <ThriveFooter />
      </div>

      <PulseEntry />
    </div>
  );
}

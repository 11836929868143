import tiaLogo from "@assets/logo-tia-full.svg";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import StoryThreadStories from "@pages/StoryThread/components/StoryThreadStories";
import { StoryThreadContext } from "@pages/StoryThread/utilities/types";
import {
  cn,
  replaceTextSingleQuote,
  toUnderscoreFromSpace,
} from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

type DefaultStoryThreadLayoutProps = Pick<
  StoryThreadContext,
  "data" | "entity"
>;
export default function DefaultStoryThreadLayout({
  data: { title, articles },
  entity,
}: DefaultStoryThreadLayoutProps): React.ReactElement {
  const breakingNews = useTrimmedBreakingNews();
  const mostPopular = useMostReadArticleList();
  const adBlockDetected = useDetectAdBlock();

  const { name, urlPath } = entity;
  const titleReplaceSingleQuote = replaceTextSingleQuote(name);

  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={urlPath}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        level2="story-thread"
        title={`${toUnderscoreFromSpace(titleReplaceSingleQuote)}_Index`}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${name} - Find ${name} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={urlPath}
        robots="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full">
            {entity.urlPath === "/tech-in-asia" ? (
              <div className="mb-6 border-b border-gray-850 pb-3 pt-6">
                <img
                  src={tiaLogo}
                  alt="Tech in Asia"
                  width={271}
                  height={47}
                  className="mx-auto lg:mx-0"
                />
              </div>
            ) : (
              <h1
                className={cn(
                  "mb-6 border-b border-gray-850 pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850",
                  "md:text-8xl"
                )}
              >
                {entity.name}
              </h1>
            )}
          </Column>
        </Row>

        <Row>
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <StoryThreadStories
              {...{ storyThreadUrlPath: urlPath, articles }}
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-6">
            <div className="mb-6 lg:h-[800px]">
              <Ad
                adType="imu2"
                className="flex flex-col items-center justify-center lg:sticky lg:top-[60px]"
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

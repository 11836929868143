import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";

export default function ContactUsPage(): React.ReactElement {
  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "catfish", "abm"]}
        path={RouteFactory.contactUs}
      />

      <MetaTags
        title="Contact Us"
        description="Read more at The Business Times."
        slug={RouteFactory.contactUs}
      />

      <GAData title="Contact_Us" level2="miscellaneous" contentcat={1} />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <div
        className="container !mt-6 px-3 font-poppins"
        data-testid="contact-us-component"
      >
        <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            Advertisers
          </h3>
          <p>
            If you would like to advertise on <em>The Business Times</em>,
            please contact us at
          </p>
          <p>
            Phone: (65) 6319 6319
            <br />
            Email:{" "}
            <a
              href="mailto:mkgsales@sph.com.sg"
              className="text-verticals-btblue hover:text-black hover:underline"
            >
              mkgsales@sph.com.sg
            </a>
            <br />
            Website:{" "}
            <a
              href="http://www.imsph.sg"
              target="_blank"
              className="text-verticals-btblue hover:text-black hover:underline"
              rel="noreferrer"
            >
              www.imsph.sg
            </a>
          </p>

          <p>&nbsp;</p>

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            Readers
          </h3>

          <ul className="list-disc" style={{ paddingLeft: 30 }}>
            <li>
              If you have an opinion to share about what you’ve read, send your{" "}
              <strong>Letter to the Editor</strong> to{" "}
              <a
                href="mailto:btletter@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btletter@sph.com.sg
              </a>
              .
            </li>
            <li>
              For all subscription-related issues, email{" "}
              <a
                href="mailto:btocs@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btocs@sph.com.sg
              </a>
              , or call (65) 6388 3838.
            </li>
            <li>
              To access archived content from <em>The Business Times</em>,
              including rights to republish, click{" "}
              <a
                href="https://www.sph.com.sg/contact-us/#content-licensing"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                here
              </a>
              .
            </li>
            <li>
              Email{" "}
              <a
                href="mailto:btuserfeedback@sph.com.sg"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                btuserfeedback@sph.com.sg
              </a>{" "}
              for technical issues on digital platforms.
            </li>
          </ul>

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">&nbsp;</h3>

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">
            Newsmakers
          </h3>

          <ul className="list-disc" style={{ paddingLeft: 30 }}>
            <li>
              If you have a press release or a news tip-off to share, please
              email{" "}
              <a
                href="mailto:btnews@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btnews@sph.com.sg
              </a>
              . You may also call the newsdesk at (65) 6319 5318.
            </li>
            <li>
              To share any comments or suggestions about our news coverage, or
              to report errors that require correction, please email{" "}
              <a
                href="mailto:btnews@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btnews@sph.com.sg
              </a>
              . Our reporters can also be reached directly at the email address
              or Twitter handles that accompany their bylines on the articles
              they write.
            </li>
            <li>
              To get in touch with our foreign correspondents and writers,
              please email{" "}
              <a
                href="mailto:btworld@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btworld@sph.com.sg
              </a>
              .
            </li>
            <li>
              For BT Podcasts or webinars, email{" "}
              <a
                href="mailto:btdigital@sph.com.sg"
                className="text-verticals-btblue hover:text-black hover:underline"
              >
                btdigital@sph.com.sg
              </a>
              .
            </li>
            <li>
              For editorial partnerships and projects, email{" "}
              <a
                href="mailto:btprojects@sph.com.sg"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                btprojects@sph.com.sg
              </a>
              .
            </li>
            <li>
              To contact the Lifestyle section, email{" "}
              <a
                href="mailto:btlifestyle@sph.com.sg"
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                btlifestyle@sph.com.sg
              </a>
              .
            </li>
          </ul>

          <h3 className="mb-2 mt-1 text-5xl font-medium lg:text-6xl">&nbsp;</h3>
        </div>
      </div>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

import { NavigationKeysEnums } from "@app/types/enums";
import DefaultIndividualSideMenu from "@components/Header/SideMenu/DefaultIndividualSideMenu";
import type { IndividualSection } from "@components/Header/types";
import { cn } from "@util/helpers";

export interface IndividualSideMenu {
  menuItems: IndividualSection;
}

export default function IndividualSideMenuSection({
  menuItems,
}: IndividualSideMenu) {
  const {
    label: sectionName,
    key: sectionKey,
    children: items,
  } = menuItems.section;

  return (
    <>
      <div
        className={cn({
          "block lg:hidden":
            sectionKey === NavigationKeysEnums.hamburgerTop && sectionName,
        })}
      >
        {sectionName ? (
          <>
            <p className="m-0 mx-8 pb-2 text-xs font-semibold uppercase text-gray-350 lg:hidden">
              {sectionName}
            </p>
            <div className="mx-8 h-[1px] w-auto bg-gray-175 lg:hidden"></div>
          </>
        ) : null}
      </div>

      <div
        className={cn(
          "grid grid-cols-2 gap-1 pt-1 lg:block lg:border-b lg:border-gray-175",
          {
            "grid lg:hidden": sectionKey === NavigationKeysEnums.hamburgerTop,
            "pb-0 lg:pb-1":
              sectionKey === NavigationKeysEnums.hamburgerRecommends,
            "pb-4 lg:pb-1":
              sectionKey !== NavigationKeysEnums.hamburgerRecommends,
          }
        )}
      >
        {items?.map((sideNavigationItem, index) => {
          const { OtherComponent, extraClass } = sideNavigationItem;

          if (OtherComponent) {
            return <OtherComponent key={index} />;
          }

          return (
            <DefaultIndividualSideMenu
              key={index}
              index={index}
              sectionKey={sectionKey}
              sideNavigationItem={sideNavigationItem}
              className={extraClass}
            />
          );
        })}
      </div>
    </>
  );
}

import { useEffect, useRef } from "react";
import { cn } from "@util/helpers";

import PulseEntryButton from "./PulseEntryButton";
import PulseEntryToolTip from "./PulseEntryToolTip";

export default function PulseEntry(): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (containerRef.current) {
      const getContainerWidth =
        window.document.getElementsByClassName("container")?.[0].clientWidth;
      containerRef.current.style.right = `calc((100svw - ${getContainerWidth}px)/ 2)`;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window === "undefined") return;

      const getContainerWidth =
        window.document.getElementsByClassName("container")?.[0].clientWidth;

      if (containerRef.current) {
        containerRef.current.style.right = `calc((100svw - ${getContainerWidth}px)/ 2)`;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={cn("fixed bottom-24 right-4 z-[99999] px-3 lg:px-0")}
      data-testid="pulse-entry-component"
      ref={containerRef}
    >
      <PulseEntryToolTip />

      <PulseEntryButton />
    </div>
  );
}

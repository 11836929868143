import { Section, Tag } from "@app/types/Cue";

import { PageProps } from "./types";

/**
 * Helper function to derive the title of the page.
 * @param props The page properties, may include kind, term name, and article title.
 * @returns {string}
 */
export const renderPageTitleV2 = (props: PageProps): string => {
  const { kind } = props;

  switch (kind) {
    case "art":
    case "newsletter":
    case "static": {
      const { sectionName, title } = props;

      if (sectionName) {
        return `${title}, ${sectionName} - The Business Times`;
      }

      return `${title} - The Business Times`;
    }

    case "custom": {
      const { title } = props;
      return title;
    }
    case "keyword":
    case "storythread": {
      const { entity } = props;

      // Extract meta title using helper function
      const metaTitle = extractMetaField(entity, "metaTitle");
      return (
        metaTitle ||
        `${entity.name} Latest News & Headlines - The Business Times`
      );
    }
    case "section": {
      const { sectionName, sectionParentName } = props;

      // Include sectionParentName only if it's not empty and not "Home"
      const formattedParentName =
        sectionParentName && sectionParentName !== "Home"
          ? ` - ${sectionParentName}`
          : "";

      return `${sectionName}${formattedParentName} - The Business Times`;
    }
    case "author": {
      const { authorName, authorDesignation } = props;

      // Include authorDesignation only if it exists
      const designationPart = authorDesignation
        ? ` - ${authorDesignation}`
        : "";

      return `${authorName}${designationPart} - The Business Times`;
    }
    case "home":
    default:
      return "The Business Times - Get the Latest Business & Financial News";
  }
};

/**
 * Extracts the value of a specific meta field from a tag entity.
 * @param entity The tag entity containing metadata fields.
 * @param fieldName The name of the field to extract.
 * @returns {string | undefined}
 */
export const extractMetaField = (
  entity: Tag | Section,
  fieldName: string
): string | undefined => {
  // Check if the entity has fields (indicating it is a Tag)
  if ("fields" in entity && entity.fields) {
    const field = entity.fields.find((field) => field.key === fieldName);
    return field?.value;
  }

  // If the entity is a Section, return undefined and ignore metaDescription
  // Meta fields are not applicable for Section entities
  return undefined;
};

/**
 * Helper function to derive the meta description of the page from CUE TagManager.
 * @param props The page properties, may include kind, tag Entity.
 * @returns {string}
 */
export const renderMetaDescription = (props: PageProps): string => {
  const { kind } = props;

  switch (kind) {
    case "keyword":
    case "storythread": {
      const { entity } = props;
      const termName = entity.name;

      // Return metaDescription if available; otherwise, return the default format
      return (
        extractMetaField(entity, "metaDescription") ||
        `THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`
      );
    }
    default:
      return "The Business Times - Get the Latest Business & Financial News.";
  }
};

import { RouteFactory } from "@app/routePaths";
import thriveCurveWord from "@assets/thrive_images/thrive-curve-word.webp";
import GAData from "@components/GAData/GAData";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import ThriveFavicon from "@pages/Thrive/components/ThriveFavicon";
import ThriveFooter from "@pages/Thrive/components/ThriveFooter";
import ThriveGetSocial from "@pages/Thrive/components/ThriveGetSocial";
import ThriveHeader from "@pages/Thrive/components/ThriveHeader";
import ThriveMiniAboutUs from "@pages/Thrive/components/ThriveMiniAboutUs";
import { thriveHomePageMetaTags } from "@pages/Thrive/constants";
import ThriveHomeNewsletterSignUp from "@pages/Thrive/Home/components/ThriveHomeNewsletterSignUp/ThriveHomeNewsletterSignUp";
import ThriveStartConversation from "@pages/Thrive/Home/components/ThriveStartConversation/ThriveStartConversation";

export default function ThriveHome(): React.ReactElement {
  const MetaTagsProps = thriveHomePageMetaTags;
  const title = renderPageTitleV2({
    kind: "custom",
    title:
      "thrive - The Business Times | Career, Money and Life for Gen Zs in Singapore",
  });
  return (
    <div
      className="w-full overflow-hidden bg-verticals-thrive"
      id="thrive-home-page"
      data-testid="thrive-home-page"
    >
      <ThriveFavicon />

      <MetaTags
        title={title}
        ogSiteName={title}
        description={MetaTagsProps.description}
        ogDescription={MetaTagsProps.description}
        ogImageAlt={MetaTagsProps.description}
        ogImageUrl={MetaTagsProps.ogImageUrl}
        slug={RouteFactory.thrive}
      />

      <GAData title="Thrive_Events" level2="miscellaneous" />

      <ThriveHeader />

      <ThriveHomeNewsletterSignUp />

      <div className="overflow-hidden py-28">
        <img
          className="w-full scale-[3] lg:scale-[1.3]"
          src={thriveCurveWord}
          alt="One two thrive"
          height={99}
          width={1800}
        />
      </div>

      <ThriveStartConversation />

      <ThriveGetSocial />

      <ThriveMiniAboutUs />

      <div className="flex justify-center bg-gray-850 font-poppins">
        <ThriveFooter />
      </div>

      <PulseEntry />
    </div>
  );
}

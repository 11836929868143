import { Link } from "react-router-dom";
import { SCREEN_LG } from "@util/constant";
import { cn } from "@util/helpers";

export type SectionNavigationProps = {
  link: string;
  label: string;
  testId?: string;
  headerWidth?: number;
  isForArticle?: boolean;
};

export default function SectionNavigation({
  link,
  label,
  testId = "section-navigation-component",
  headerWidth = 0,
  isForArticle,
}: SectionNavigationProps): React.ReactElement {
  if (!link || !label) return <></>;

  const isShown =
    (isForArticle && headerWidth >= 1300) ||
    (!isForArticle && headerWidth > SCREEN_LG);

  return (
    <div
      data-testid={testId}
      className={cn("hidden items-center", { flex: isShown })}
    >
      <div className="mx-3 h-[24px] w-[1px] flex-none bg-gray-175"></div>
      <p className="m-0 mr-3 flex h-9 w-auto items-center justify-center text-nowrap rounded-[25px] px-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 lg:hover:bg-gray-250">
        <Link to={link} rel="noopener noreferer" reloadDocument>
          {label}
        </Link>
      </p>
    </div>
  );
}

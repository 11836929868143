import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import thumbnail from "@assets/budget/mti_cover.png";
import { BlockTitle } from "@blocks/Blocks";

export type BookletProps = {
  booklet: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Booklet({
  className,
  booklet,
}: BookletProps): React.ReactElement {
  if (!booklet) return <></>;

  return (
    <div className={className} data-testid="booklet-component">
      <BlockTitle
        text="MTI BUSINESS BUDGET BOOK"
        rootClassName="mb-4 !text-lg lg:!text-3xl"
      />

      <div className="group relative flex gap-4">
        <img
          src={thumbnail}
          width={100}
          height={67}
          alt="Singapore Budget 2025"
          className="aspect-3x2 object-contain"
        />

        <h2 className="font-lct text-base font-bold group-hover:underline lg:text-lg">
          {"Singapore Economy 2030: Grow Our Economy"}
        </h2>

        <Link to={booklet} target="_blank" className="absolute inset-0" />
      </div>
    </div>
  );
}

import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import thumbnail from "@assets/budget2024-infographics-thumbnail.png";
import { BlockTitle } from "@blocks/Blocks";

export type InfographicsProps = {
  link?: string;
  testId?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Infographics({
  testId,
  className,
  link,
}: InfographicsProps): React.ReactElement {
  if (!link) return <></>;

  return (
    <div className={className} data-testid={testId}>
      <BlockTitle text="INFOGRAPHICS" rootClassName="mb-4" />

      <div className="group relative flex gap-4">
        <img
          src={thumbnail}
          width={100}
          height={67}
          alt="Singapore Budget 2025"
          className="aspect-3x2 object-contain"
        />

        <h2 className="font-lct text-base font-bold group-hover:underline lg:text-lg">
          Key Budget Numbers
        </h2>

        <Link to={link} target="_blank" className="absolute inset-0" />
      </div>
    </div>
  );
}

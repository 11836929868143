import { useState } from "react";
import { Element } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import Container from "@components/Container/Container";
import { useWindowInnerWidth } from "@hooks/useWindowInnerWidth";
import ArticleExploreMore from "@pages/Article/components/ArticleExploreMore/ArticleExploreMore";
import { getExploreMoreObjectByTags } from "@pages/Article/components/ArticleExploreMore/helpers.ts";
import ArticleNewsletter from "@pages/Article/components/ArticleNewsletter/ArticleNewsletter";
import { ArticleParagraphsProps } from "@pages/Article/components/ArticleParagraphs";
import ArticleReadMore from "@pages/Article/components/ArticleReadMore";
import ArticleSeeAlso from "@pages/Article/components/ArticleSeeAlso";
import { display3rdParagraphImu } from "@pages/Article/utils/helpers";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { checkIfFromPaidPressRelease, cn } from "@util/helpers";

import Paragraph from "./Paragraph";

type PropsToImport =
  | "id"
  | "seeAlso"
  | "displayRelatedLinksAndNewsletterSignupForm"
  | "keywords"
  | "index"
  | "gsChannels"
  | "sections"
  | "readMoreArticle";

type ParagraphWrapperProps = Pick<ArticleParagraphsProps, PropsToImport> & {
  element: Element;
  paragraphCount: number;
  isBoxWrapped?: boolean;
  totalParagraphs: number;
  adsWrapperClass?: string;
  isDisplayImu1?: boolean;
  isBrandedContent?: boolean;
  fullScreenAds?: boolean;
  elementsClass?: string;
  displayStyleForDefaultLayout?: boolean;
  isPremium?: boolean;
  isSubscriber?: boolean;
};

export default function ParagraphWrapper({
  id,
  element,
  paragraphCount,
  index,
  sections,
  keywords,
  gsChannels = "no_analysis",
  displayRelatedLinksAndNewsletterSignupForm,
  seeAlso,
  isBoxWrapped = false,
  totalParagraphs,
  adsWrapperClass,
  isBrandedContent = false,
  readMoreArticle,
  elementsClass,
  displayStyleForDefaultLayout = false,
  fullScreenAds = false,
  isPremium = false,
  isSubscriber = false,
}: ParagraphWrapperProps): React.ReactElement {
  const mainSection = sections?.[0];
  const isPaidPressRelease = checkIfFromPaidPressRelease(sections);
  const isDisplayAds = !isBoxWrapped && !isPaidPressRelease;
  const windowInnerSize = useWindowInnerWidth();
  const [adsHeight, setAdsHeight] = useState(0);

  const noAdsDisplayed = adsHeight <= 5;

  const adsExtraCss = {
    maxWidth: `${windowInnerSize.width}px`,
    marginLeft: fullScreenAds
      ? `calc(-1*(${windowInnerSize.width}px - 100%) / 2 )`
      : 0,
    height: fullScreenAds
      ? noAdsDisplayed
        ? 0
        : `${adsHeight + 125}px`
      : "auto",
    marginBottom: noAdsDisplayed ? "0" : "2rem",
  };

  const isHideAds = fullScreenAds && noAdsDisplayed;
  const btTags =
    keywords?.map(({ name }) => getFormattedTextForAds(name)).join(",") || "";

  const displayExploreWidget =
    getExploreMoreObjectByTags(keywords || []).length > 0;

  return (
    <>
      <Paragraph
        element={element}
        className={cn("mb-6", elementsClass, {
          "leading-8 -tracking-5%": displayStyleForDefaultLayout,
        })}
      />

      {isDisplayAds &&
      paragraphCount === 3 &&
      totalParagraphs !== 3 &&
      display3rdParagraphImu(isSubscriber, isPremium) &&
      mainSection ? (
        <div
          className={cn("my-8 md:my-12", adsWrapperClass, {
            "min-h-0 border-y-0 bg-none py-0": isHideAds,
          })}
          style={adsExtraCss}
        >
          <Container rootClassName="sticky top-[120px]">
            <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
              <Ad
                adType="imu1"
                className="flex flex-col items-center justify-center"
                setAdsHeight={setAdsHeight}
                counter={index}
                articleId={id}
                articleTags={btTags}
                gsChannels={gsChannels}
              />
            </div>
          </Container>
        </div>
      ) : null}

      {paragraphCount === 6 ? (
        <>
          {displayRelatedLinksAndNewsletterSignupForm &&
          seeAlso?.title &&
          !isBrandedContent &&
          !displayStyleForDefaultLayout &&
          !isPaidPressRelease ? (
            <ArticleSeeAlso
              title={seeAlso.title}
              articleId={seeAlso.articleId}
              slug={seeAlso.slug}
            />
          ) : null}

          {displayRelatedLinksAndNewsletterSignupForm &&
          displayStyleForDefaultLayout &&
          totalParagraphs > 6 &&
          totalParagraphs <= 9 ? (
            <ArticleReadMore {...{ readMoreArticle }} />
          ) : null}

          {displayRelatedLinksAndNewsletterSignupForm &&
          sections &&
          keywords &&
          totalParagraphs > 9 &&
          !isPaidPressRelease ? (
            <div
              className={cn("py-10", {
                "md:-mx-8": displayStyleForDefaultLayout,
              })}
            >
              {displayExploreWidget ? (
                <div
                  className={cn("py-10", {
                    "md:-mx-8": displayStyleForDefaultLayout,
                  })}
                >
                  <ArticleExploreMore keyword={keywords} />
                </div>
              ) : (
                <ArticleNewsletter
                  rootClassName={
                    displayStyleForDefaultLayout
                      ? "mb-6 border border-gray-175 p-6 md:p-8"
                      : "border-y-2 border-gray-850"
                  }
                  sections={sections}
                  tags={keywords}
                  displayNewVersionNewsletter={displayStyleForDefaultLayout}
                />
              )}
            </div>
          ) : null}

          {isDisplayAds && totalParagraphs !== 6 && mainSection ? (
            <div
              className={cn(adsWrapperClass, {
                "min-h-0 border-y-0 bg-none py-0": isHideAds,
              })}
              style={adsExtraCss}
            >
              <Container rootClassName="sticky top-[120px]">
                <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
                  <Ad
                    adType="midarticlespecial"
                    className="flex flex-col items-center justify-center"
                    setAdsHeight={setAdsHeight}
                    counter={index}
                    articleId={id}
                    articleTags={btTags}
                    gsChannels={gsChannels}
                  />
                </div>
              </Container>
            </div>
          ) : null}
        </>
      ) : null}

      {paragraphCount === 9 ? (
        <>
          {displayRelatedLinksAndNewsletterSignupForm &&
          seeAlso?.title &&
          !displayStyleForDefaultLayout &&
          !isPaidPressRelease ? (
            <ArticleSeeAlso
              title={seeAlso.title}
              articleId={seeAlso.articleId}
              slug={seeAlso.slug}
            />
          ) : null}

          {displayRelatedLinksAndNewsletterSignupForm &&
          displayStyleForDefaultLayout &&
          totalParagraphs > 9 &&
          !isPaidPressRelease ? (
            <ArticleReadMore {...{ readMoreArticle }} />
          ) : null}

          {isDisplayAds && totalParagraphs !== 9 && mainSection ? (
            <div
              className={cn("my-8 md:my-12", adsWrapperClass, {
                "min-h-0 border-y-0 bg-none py-0": isHideAds,
              })}
              style={adsExtraCss}
            >
              <Container rootClassName="sticky top-[120px]">
                <div className="mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
                  <Ad
                    adType="imu2"
                    className="flex flex-col items-center justify-center"
                    setAdsHeight={setAdsHeight}
                    counter={index}
                    articleId={id}
                    articleTags={btTags}
                    gsChannels={gsChannels}
                  />
                </div>
              </Container>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}

import Ad from "@components/Advertisement/Ad";
import Container from "@components/Container/Container";
import { useWindowInnerWidth } from "@hooks/useWindowInnerWidth";
import { SearchResultProps } from "@pages/Search/types";

import SearchBasicCard from "./SearchBasicCard";

export default function SearchResults({
  result,
  cardCount,
}: SearchResultProps): React.ReactElement {
  const windowInnerSize = useWindowInnerWidth();

  const adsExtraCss = {
    maxWidth: `${windowInnerSize.width}px`,
    marginLeft: `calc(-1*(${windowInnerSize.width}px - 100%) / 2 )`,
    boxShadow: "inset 0px 0px 0px 1px #eeeeee",
  };

  return (
    <>
      <SearchBasicCard result={result} cardCount={cardCount} />

      {cardCount === 4 ? (
        <div className="w-screen bg-gray-125" style={adsExtraCss}>
          <Container rootClassName="sticky top-[120px]">
            <div className="mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
              <Ad
                adType="bn1"
                className="my-8 flex flex-col items-center justify-center py-6 md:my-12"
                counter={cardCount}
              />
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject, Tag } from "@app/types/Cue";
import myBTLightLogo from "@assets/logo-mybt-light.svg";
import Button from "@components/AccessibleComponents/Button";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { faPencil, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@hooks/useWindowSize";
import { sectionRequests } from "@pages/Section/Section.server";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { MYBT_EXCLUDE_KEYWORDS } from "@util/constant";
import { gaEventTracker } from "@util/helpers";

import AddToYourPage from "./components/MyBTPage/AddToYourPage";
import ListOfArticles from "./components/MyBTPage/ListOfArticles";

type TagsWithArticle = {
  tag: Tag;
  articles: ArticleDataObject[];
};

export default function MyBTPage(): React.ReactElement {
  const [followedTagsWithArticles, setFollowedTagsWithArticles] = useState<
    TagsWithArticle[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreTags, setHasMoreTags] = useState(true);
  const [addToYourPageLimit, setAddToYourPageLimit] = useState(0);

  const { isScreenLG } = useWindowSize();
  const navigate = useNavigate();

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userTags = useMyBtStore((store) => store.tags);

  useEffect(() => {
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.ANONYMOUS ||
    OKTAUserInfo?.mysphw == ""
      ? navigate("/mybt/intro")
      : null;
    userTags.length == 0 ? navigate("/mybt/onboard") : null;
  }, [OKTAUserInfo, userTags, navigate]);

  const handleClick = () => {
    // Redirect to onboard page
    gaEventTracker("mybt", "click", "edit");
    navigate("/mybt/onboard");
  };

  const fetchArticles = useCallback(async () => {
    setIsLoading(true);

    const pageSize = 3; // Adjust as needed

    const tagsToLoad = userTags
      .filter((tag) => !MYBT_EXCLUDE_KEYWORDS.includes(tag.name))
      .slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    if (tagsToLoad.length === 0) {
      setHasMoreTags(false);
      setIsLoading(false);
      return;
    }

    setCurrentPage((prevPage) => prevPage + 1);

    const newTagsWithArticles = await sectionRequests
      .fetchArticlesByKeywords(tagsToLoad, 5)
      .then((response) => response.data.body)
      .then((response) => {
        return Object.keys(response).reduce<TagsWithArticle[]>(
          (accumulator, current) => {
            const tag = userTags.find(
              ({ urlPath }) => urlPath === `/keywords/${current}`
            );

            if (!tag) return accumulator;

            return [
              ...accumulator,
              {
                tag: tag,
                articles: response[current].body,
              },
            ];
          },
          []
        );
      });

    setFollowedTagsWithArticles((prev) => [...prev, ...newTagsWithArticles]);
    setAddToYourPageLimit((prevCount) => prevCount + 1);
    setIsLoading(false);
  }, [currentPage, userTags]);

  // Fetch article on initial load
  useEffect(() => {
    if (currentPage === 0) fetchArticles();
  }, [currentPage, fetchArticles]);

  // Infinity scroll
  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (inView && hasMoreTags && currentPage != 0 && !isLoading) {
        fetchArticles();
      }
    },
  });

  return (
    <div className="w-full" data-testid="my-bt-page-component">
      <AdSettings
        adNames={["lb1", "imu2", "catfish", "abm"]}
        path={RouteFactory.myBT}
      />

      <MetaTags
        title={renderPageTitleV2({
          kind: "static",
          title: "myBT",
        })}
        description="Read more at The Business Times."
        slug="/mybt"
      />

      <GAData
        chapter1="mybt"
        level2="mybt"
        title="myBT - THE BUSINESS TIMES"
        adblocker={typeof window !== undefined ? 0 : window.canRunAds}
      />

      <Ad
        adType="lb2"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <>
        <Header />

        <div className="w-full">
          <div className="mb-10 bg-gray-250 py-6">
            <Container>
              <div className="relative">
                <img
                  src={myBTLightLogo}
                  width={120}
                  height={45}
                  className="mx-auto"
                  alt="myBT Logo"
                />

                <Button
                  className="absolute right-0 top-1/2 aspect-square -translate-y-1/2 rounded-full border border-gray-850 bg-white px-4 font-poppins font-bold lg:aspect-auto lg:px-6 lg:py-2"
                  onPress={handleClick}
                >
                  <FontAwesomeIcon icon={faPencil} className="lg:mr-2" />

                  {isScreenLG ? <span>EDIT</span> : null}
                </Button>
              </div>
            </Container>
          </div>

          <Container>
            <>
              <Row rootClassName="mb-12">
                {followedTagsWithArticles.map(({ tag, articles }, index) => {
                  const cycleIndex = index % 3;
                  return (
                    <React.Fragment key={`${tag.urlPath || tag.uri}-${index}`}>
                      <ListOfArticles tag={tag} articles={articles} />
                      {/* Conditionally render AddToYourPage after a specific article, e.g., after the first cycle */}
                      <Column rootClassName="w-full lg:w-4/12 my-5 lg:my-0">
                        {cycleIndex === 1 ? (
                          <AddToYourPage
                            limit={addToYourPageLimit}
                            index={index}
                          />
                        ) : (
                          <Ad
                            adType="imu1"
                            className="bg-stripes py-4"
                            counter={index}
                          />
                        )}
                      </Column>
                    </React.Fragment>
                  );
                })}
              </Row>
              <div ref={ref}></div>
            </>
          </Container>
        </div>
      </>

      {isLoading ? (
        <div className="my-6 text-center">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin height="3em" />
        </div>
      ) : null}

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

import { useEffect } from "react";
import { useFirstMountState } from "react-use";
import NewsBarDesktop from "@components/NewsBar/NewsBarDesktop";
import NewsBarMobile from "@components/NewsBar/NewsBarMobile";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

import type { NewsBarProps } from "./utils/types";

export default function NewsBar({
  articles = [],
  className,
}: NewsBarProps): React.ReactElement {
  const isFirstMount = useFirstMountState();
  const sendDataToGTM = useGTMDispatch();
  const { isScreenLG } = useWindowSize();

  useEffect(() => {
    if (!isFirstMount) return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "visible",
      eventLabel: "news bar",
    });
  }, [isFirstMount, sendDataToGTM]);

  if (articles.length === 0) return <></>;

  return (
    <div
      className={cn(
        "flex h-full w-full items-center justify-center bg-blue-200/[0.07] px-4 lg:justify-normal",
        className
      )}
      data-testid="news-bar-component"
    >
      {isScreenLG ? (
        <NewsBarDesktop articles={articles} className="hidden w-full lg:flex" />
      ) : (
        <NewsBarMobile className="flex lg:hidden" />
      )}
    </div>
  );
}

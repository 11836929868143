import { HtmlHTMLAttributes, RefObject, useEffect } from "react";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

type ProgressBarProps = Pick<
  HtmlHTMLAttributes<HTMLDivElement>,
  "className"
> & {
  barClassname?: string;
  progressBarContainerRef: RefObject<HTMLDivElement>;
  progressBarRef: RefObject<HTMLDivElement>;
};

export default function ProgressBar({
  className,
  barClassname,
  progressBarContainerRef,
  progressBarRef,
}: ProgressBarProps): React.ReactElement {
  const windowSize = useWindowSize();

  useEffect(() => {
    if (typeof window === "undefined") return;

    const getContainerWidth = window.document.querySelector(
      "[data-testid='navigation-bar']"
    )?.clientWidth;

    if (progressBarContainerRef.current) {
      progressBarContainerRef.current.style.maxWidth = `${getContainerWidth}px`;
    }
  }, [progressBarContainerRef, windowSize]);

  return (
    <div
      ref={progressBarContainerRef}
      className={cn(
        "fixed left-1/2 top-[50px] z-[10000] w-full -translate-x-1/2 bg-white",
        className
      )}
    >
      <div
        ref={progressBarRef}
        className={cn(
          "h-[0.125rem] w-0 bg-gray-850 transition-all duration-150",
          barClassname
        )}
      ></div>
    </div>
  );
}

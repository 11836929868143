/**
 * Company: SPHMedia
 * Description: ESG Section Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { getSectionJSONLD } from "@app/util/helpers";
import esgLogo from "@assets/verticals/esg-logo.png";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import {
  useBreadCrumbState,
  useCategoriesFromPath,
} from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import Overview from "@pages/Section/components/Overview/Overview";
import type { SectionDefaultProps } from "@pages/Section/types";
import { sectionNavigationItems } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";
import cx from "classnames";

export default function ESGSectionLayout({
  data: { entity, title, overview },
}: SectionDefaultProps): ReactElement {
  const { parentCategory, childCategory } = useCategoriesFromPath();

  const mostPopular = useMostReadArticleList();
  const breakingNews = useTrimmedBreakingNews();
  const adBlockDetected = useDetectAdBlock();

  const parentCategoryData = sectionNavigationItems?.[parentCategory];
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();

  return (
    <div className="w-full" data-testid="esg-section-layout-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.esg}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="THE BUSINESS TIMES ESG - Find ESG News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.esg}
        jsonLd={getSectionJSONLD(entity)}
      />

      <GAData
        title={title}
        level2="ESG_Index"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header
        displayBreadcrumb={
          parentCategory &&
          sectionNavigationItems?.[parentCategory] &&
          isShowBreadCrumb
            ? true
            : false
        }
        parentCategory={parentCategory}
        childCategory={childCategory}
        categoryDataMapping={sectionNavigationItems}
      />

      <PulseEntry />

      {parentCategory && sectionNavigationItems?.[parentCategory] ? (
        <div className="w-full flex-none">
          <Container rootClassName="w-full">
            <h1
              className={cx(
                "pb-3 pt-6 font-playfair text-8xl font-semibold uppercase text-gray-850"
              )}
            >
              {parentCategoryData.label}
            </h1>

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="container m-auto"
                parentCategory={parentCategory}
                childCategory={childCategory}
                categoryDataMapping={sectionNavigationItems}
              />
            </div>
          </Container>
        </div>
      ) : null}

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full">
            <NewsletterSignUpPanel
              rootClassName="mb-6 lg:py-0"
              containerClassName="lg:pl-0"
              firstColumnClassName="lg:!w-5/12"
              secondColumnClassName="mb-4 lg:!w-6/12"
              descriptionComponent={
                <div
                  className={cx(
                    "mb-4 flex items-center justify-center",
                    "lg:mb-0 lg:justify-start"
                  )}
                >
                  <img
                    className="aspect-square w-[48px] lg:w-[90px]"
                    src={esgLogo}
                    alt="ESG"
                    width={90}
                    height={90}
                  />
                  <h4
                    className={cx(
                      "ml-4 font-public-sans text-base font-semibold lg:text-4xl"
                    )}
                  >
                    Get weekly{" "}
                    <span className="text-verticals-esg">{"ESG "}</span>
                    <br className="md:hidden lg:inline xl:hidden" />
                    updates in your inbox
                  </h4>
                </div>
              }
              newsletter={BT_NEWSLETTERS.filter(
                (newsletter) => newsletter.type === NEWSLETTER_TYPE.ESG
              )}
            />
          </Column>

          <Column rootClassName="w-full lg:w-8/12">
            <Overview data={overview} />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <div className="mb-6 lg:h-[800px]">
              <Ad
                adType="imu2"
                className="flex flex-col items-center justify-center lg:sticky lg:top-[70px]"
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

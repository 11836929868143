import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { useRouteContext } from "@sphtech/web2-core/ssr";

import { VideosPageContext } from "./utils/types";
import VideoPageContent from "./VideoPageContent";

export default function VideosPage(): React.ReactElement {
  const { context } = useRouteContext<VideosPageContext>();

  return (
    <Await resolve={context} errorElement={<CustomError />}>
      {({ kind, error, data }) => {
        return kind !== "static" || error ? (
          <h1>Missing context</h1>
        ) : (
          <VideoPageContent {...{ data }} />
        );
      }}
    </Await>
  );
}

import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import LoadMore from "@components/LoadMore/LoadMore";
import { useMoreVideos } from "@hooks/userMoreVideos";
import { textToSlug } from "@util/helpers";
import dayjs from "dayjs";

export type MoreVideosProps = {
  className?: string;
  videos: BrightcoveVideo[];
};

export default function MoreVideos({
  className,
  videos,
}: MoreVideosProps): React.ReactElement {
  const { moreVideos, handleLoadMoreVideos, hasMoreVideos } =
    useMoreVideos(videos);

  return (
    <div className={className}>
      <BlockTitle text="MORE VIDEOS" rootClassName="mb-3" />

      <div className="mb-8 grid gap-6 lg:grid-cols-2">
        {moreVideos.map(({ id, name, images, duration }) => {
          const thumbnail = {
            content: {
              type: "picture",
              url: images.thumbnail.src,
            },
          };
          const variant: CardVariations = {
            image: {
              isVideo: true,
              position: "left",
              width: "w-120px lg:w-1/4",
              aspectRatio: "aspect-ratio-16/9",
            },
            content: {
              width: "w-full-120px lg:w-3/4",
              extraClass: "pl-4",
            },
            title: {
              color: "text-gray-850",
              size: "text-base",
              extraClass: "font-lct",
            },
          };

          return (
            <BasicCard
              key={id}
              id={id}
              slug={`/videos/${textToSlug(name)}/${id}`}
              title={name}
              media={thumbnail}
              variations={variant}
              kicker={dayjs.duration(duration).format("mm:ss")}
            />
          );
        })}
      </div>

      <LoadMore
        loadText="MORE VIDEOS"
        onLoadMore={handleLoadMoreVideos}
        rootClassName="mt-4"
        hasMore={hasMoreVideos}
      />
    </div>
  );
}

import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { VideosDetailsPageContext } from "@pages/Videos/utils/types";
import { useRouteContext } from "@sphtech/web2-core/ssr";

import VideosDetailsPageContent from "./VideosDetailsPageContent";

export default function VideosDetailsPage(): React.ReactElement {
  const { context } = useRouteContext<VideosDetailsPageContext>();

  return (
    <Await resolve={context} errorElement={<CustomError />}>
      {({ error, kind, data: { title, video, relatedVideos, videoUrl } }) => {
        if (error || kind !== "static") {
          return <CustomError statusCode={context.statusCode} />;
        }

        return (
          <VideosDetailsPageContent
            {...{ title, video, relatedVideos, videoUrl }}
          />
        );
      }}
    </Await>
  );
}

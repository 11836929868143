import React from "react";
import { Await } from "react-router-dom";
import { ArticleContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import ArticleContent from "./Article/ArticleContent";
import StoryThreadPage from "./StoryThread/StoryThreadPage";
import { StoryThreadContext } from "./StoryThread/utilities/types";

export default function DefaultPageHandler(): React.ReactElement {
  const { context } =
    useRouteContext<
      TRouteWithRedirect<ArticleContext | StoryThreadContext, string>
    >();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {(context: ArticleContext | StoryThreadContext) => {
        const { kind, data } = context;

        // Load article page content.
        if (kind === "art") {
          const {
            cook: {
              data: { context: _context },
            },
            sectionNews,
            seeAlso,
            isPreview,
          } = data;

          return (
            <ArticleContent
              {...{
                context: _context,
                sectionNews,
                seeAlso,
                isPreview,
              }}
            />
          );
        }

        // Load storythread page content.
        if (kind === "storythread") {
          const { entity } = context;

          return <StoryThreadPage {...{ data, entity }} />;
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}

import { Link } from "react-router-dom";
import {
  COMMON_BUTTON_CSS,
  REMOVE_FOCUS_CSS,
} from "@components/Button/constants";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { cn } from "@util/helpers";

import { NEWS_BAR_SECTION, NEWS_BAR_SECTION_URL } from "./utils/constants";
import type { NewsBarProps } from "./utils/types";

import "@greglaisph/react-splide/css";

export default function NewsBarDesktop({
  articles = [],
  className,
}: NewsBarProps): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  const optionsForNewsBar: Options = {
    type: "loop",
    direction: "ttb",
    height: "42px",
    perMove: 1,
    perPage: 1,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    pagination: false,
    interval: 5000,
    arrows: true,
  };

  const handleSendDataToGTM = (eventLabel: string) => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "click",
      eventLabel: eventLabel,
    });
  };

  return (
    <div
      className={cn("flex h-full items-center justify-center", className)}
      data-testid="news-bar-desktop-component"
    >
      <Link
        to={NEWS_BAR_SECTION_URL}
        onClick={() => {
          handleSendDataToGTM("news bar");
        }}
        reloadDocument
        className="group flex-none"
      >
        <p className="m-0 mr-3 flex-none border-r border-gray-175 pr-3 font-poppins text-xs font-semibold text-blue-200 group-hover:underline group-hover:underline-offset-4">
          {NEWS_BAR_SECTION}
        </p>
      </Link>

      <Splide
        options={optionsForNewsBar}
        hasTrack={false}
        className="flex w-full items-center justify-center"
      >
        <SplideTrack className="w-9/10">
          {articles.map((article, index) => {
            return (
              <SplideSlide key={index} className="flex items-center">
                <Link
                  to={`${article.urlPath}?ref=budget2025-newsbar`}
                  className="group"
                  onClick={() => {
                    handleSendDataToGTM("news bar");
                  }}
                  reloadDocument
                >
                  <p className="line-clamp-1 h-[24px] font-lct font-bold text-gray-850 group-hover:underline group-hover:underline-offset-4">
                    {article.title}
                  </p>
                </Link>
              </SplideSlide>
            );
          })}
        </SplideTrack>

        <div
          className={cn(
            "splide__arrows relative flex h-[42px] w-1/10 items-center justify-end"
          )}
        >
          <button
            className={cn(
              "splide__arrow splide__arrow--next relative !bottom-auto !left-auto !right-auto !top-auto w-max !transform-none bg-white px-3 !opacity-100",
              REMOVE_FOCUS_CSS,
              COMMON_BUTTON_CSS
            )}
            onClick={() => {
              handleSendDataToGTM("news bar next");
            }}
          >
            Next
          </button>
          <button className={cn("splide__arrow splide__arrow--prev hidden")} />
        </div>
      </Splide>
    </div>
  );
}

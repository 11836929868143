import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import defaultBTMobileLogo from "@assets/logo-bt-mobile-black.svg";
import { useHeaderState } from "@components/Header/helpers";
import SectionNavigation from "@components/Header/SectionNavigation";
import { ArticleSocialSharingHeader } from "@pages/Article/components/ArticleSocialSharing";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { cn } from "@util/helpers";
import cx from "classnames";

import LoginTrigger from "./LoginTrigger/LoginTrigger";
import LogoMasthead from "./LogoMasthead/LogoMasthead";
import { Navigation } from "./Navigation/Navigation";
import SearchTrigger from "./SearchTrigger/SearchTrigger";
import SideMenuModal from "./SideMenu/SideMenuModal";
import SubscribeTrigger from "./SubscribeTrigger/SubscribeTrigger";
import ToggleSideMenuButton from "./ToggleSideMenuButton/ToggleSideMenuButton";
import UserInfo from "./UserInfo/UserInfo";
import type { HeaderProps, MainHeaderProps, NavigationA } from "./types";
import HeaderBreadcrumb from "../HeaderBreadcrumb/HeaderBreadcrumb";

import styles from "./Header.module.scss";

export type SocialLinkConfig = {
  /**
   * @description icon image of the social link
   */
  src: string;
  link?: string;
};

export default function Header({
  prefix,
  displayBreadcrumb = false,
  parentCategory = "",
  childCategory,
  categoryDataMapping = {},
  article,
  displayArticle,
  masthead,
  isFromHome,
  enableTopStoriesLink = true,
  navigationLeftClassName,
  navigationRightClassName,
}: MainHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const headerData: NavigationA = [];

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <HeaderContainer
        prefix={prefix}
        headerData={headerData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        displayBreadcrumb={displayBreadcrumb}
        parentCategory={parentCategory}
        childCategory={childCategory}
        categoryDataMapping={categoryDataMapping}
        displayArticle={displayArticle}
        article={article}
        isFromHome={isFromHome}
        enableTopStoriesLink={enableTopStoriesLink}
        navigationLeftClassName={navigationLeftClassName}
        navigationRightClassName={navigationRightClassName}
      />

      {masthead}

      <SideMenuModal
        headerData={headerData}
        isOpen={isOpen}
        setIsClose={handleClose}
      />
    </>
  );
}

function HeaderContainer({
  headerData,
  displayBreadcrumb = false,
  setIsOpen,
  parentCategory = "",
  childCategory = "",
  displayArticle,
  article,
  isFromHome,
  enableTopStoriesLink = true,
}: HeaderProps) {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const { isHeaderStuck, headerRef, headerWidth } = useHeaderState();

  const toggleSideMenu = () => {
    setIsOpen(true);
  };

  const parentCategoryData = sectionNavigationItems?.[parentCategory];

  const displaySubscribe =
    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER;

  const navRightContainerRef = useRef<HTMLDivElement>(null);
  const navLeftContainerRef = useRef<HTMLDivElement>(null);
  const widthToDisplayTwo = 1580;
  const widthToDisplayAll = 1600;

  useEffect(() => {
    if (
      headerWidth >= widthToDisplayTwo &&
      headerWidth < widthToDisplayAll &&
      navRightContainerRef.current &&
      navLeftContainerRef.current
    ) {
      navRightContainerRef.current.style.width = "220px";
      if (isFromHome) navLeftContainerRef.current.style.overflow = "hidden";
      return;
    }

    if (
      headerWidth >= widthToDisplayAll &&
      navRightContainerRef.current &&
      navLeftContainerRef.current
    ) {
      navRightContainerRef.current.style.width = "auto";
      if (isFromHome) navLeftContainerRef.current.style.overflow = "visible";
      return;
    }

    if (navRightContainerRef.current && navLeftContainerRef.current) {
      navRightContainerRef.current.style.width = "100px";
      if (isFromHome) navLeftContainerRef.current.style.overflow = "hidden";
    }
  }, [headerWidth, isFromHome]);

  return (
    <>
      <header
        ref={headerRef}
        data-testid="navigation-bar"
        className={cx(
          styles.componentHeader,
          "sticky left-0 top-[-1px] z-30 w-full flex-none"
        )}
      >
        <div
          className={cx(
            "relative flex min-h-[50px] w-full items-center border-b border-gray-175 bg-white"
          )}
        >
          <div
            className={cn(
              "mx-auto flex h-full min-h-[50px] w-full max-w-[1900px] px-2 lg:px-6",
              { "justify-between": !isFromHome },
              { "justify-normal": displayBreadcrumb },
              { "justify-between lg:justify-normal": isFromHome }
            )}
          >
            <div
              className={cn(
                "flex items-center",
                {
                  "overflow-hidden": isFromHome,
                },
                { "hidden lg:flex": isHeaderStuck && article }
              )}
              ref={navLeftContainerRef}
            >
              <ToggleSideMenuButton onClick={toggleSideMenu} />

              <div className="hidden flex-none lg:block">
                <SearchTrigger />
              </div>

              {isFromHome ? (
                <div className="hidden pl-3 lg:block">
                  <Navigation data={headerData} position="left" />
                </div>
              ) : null}
            </div>

            <div
              className={cn(
                "absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2",
                { hidden: displayArticle && article },
                { "block lg:hidden": isFromHome || displayBreadcrumb }
              )}
            >
              <LogoMasthead
                rootClassName="mx-auto"
                imgWidth={220}
                imgHeight={19}
              />
            </div>

            <div className="mr-auto flex items-center">
              {article && displayArticle ? (
                <div className="hidden h-9 w-9 flex-none cursor-pointer items-center justify-center opacity-100 duration-300 focus-within:outline-0 focus:outline-0 lg:flex lg:hover:rounded-full lg:hover:bg-gray-250">
                  <Link
                    to={`${RouteFactory.home}?ref=logo`}
                    rel="noopener noreferer"
                    reloadDocument
                  >
                    <img
                      src={defaultBTMobileLogo}
                      height={20}
                      width={24}
                      className="w-6"
                      alt="bt logo"
                    />
                  </Link>
                </div>
              ) : null}

              {parentCategoryData && !displayBreadcrumb ? (
                <>
                  <SectionNavigation
                    link={`${parentCategoryData.link}?ref=header`}
                    label={
                      parentCategoryData.stickyHeaderLabel ||
                      parentCategoryData.label
                    }
                    headerWidth={headerRef.current?.clientWidth}
                    isForArticle={article && displayArticle}
                  />
                </>
              ) : null}
            </div>

            {article && displayArticle ? (
              <>
                <div className="absolute left-1/2 top-1/2 flex w-full flex-1 -translate-x-1/2 -translate-y-1/2 items-center px-3 min-[991px]:max-w-[643px] min-[1170px]:max-w-[784px]">
                  <div className={cx("w-[500px]")}>
                    <p className="!mb-[0.563rem] mt-[0.563rem] line-clamp-2 font-lct font-bold leading-tight text-gray-850 lg:!mb-1 lg:mt-1">
                      {article.title}
                    </p>
                  </div>
                  <div className="ml-auto min-[1000px]:ml-0 min-[1070px]:ml-auto">
                    <ArticleSocialSharingHeader
                      articleTitle={article.title}
                      shareUrl={article.shortUrl}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {isFromHome ? (
              <div
                className={cn(
                  "hidden w-auto min-w-0 items-center justify-end overflow-hidden min-[991px]:w-[100px] min-[991px]:min-w-[100px] lg:ml-auto lg:flex"
                )}
                ref={navRightContainerRef}
              >
                <div className="hidden lg:block">
                  <Navigation data={headerData} position="right" />
                </div>

                <div className="mx-3 hidden h-[24px] w-[1px] flex-none bg-gray-175 lg:block"></div>
              </div>
            ) : null}

            {displayBreadcrumb ? (
              <HeaderBreadcrumb
                parentCategory={parentCategory}
                childCategory={childCategory}
                categoryDataMapping={sectionNavigationItems}
                enableTopStoriesLink={enableTopStoriesLink}
              />
            ) : null}

            <div
              className={cn(
                "flex items-center",
                {
                  "hidden lg:flex": isHeaderStuck && article,
                },
                { "ml-auto": displayBreadcrumb }
              )}
            >
              <div
                className={cx("flex items-center justify-center ", {
                  "lg:mr-3": displaySubscribe,
                })}
              >
                {userDisplayName ? (
                  <UserInfo
                    className="font-poppins text-2xs font-semibold"
                    userInfo={OKTAUserInfo}
                  />
                ) : (
                  <LoginTrigger title={"Login"} />
                )}
              </div>

              {displaySubscribe ? (
                <div className={cx("hidden lg:block")}>
                  <SubscribeTrigger />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </header>

      <div className={cx("block pt-1 text-center lg:hidden")}>
        {displaySubscribe ? (
          <SubscribeTrigger
            displayArrow
            title="Subscribe from S$9.90/month"
            rootClassName={
              "bg-transparent !text-orange-300 visited:!text-orange-300 font-normal"
            }
          />
        ) : null}
      </div>
    </>
  );
}

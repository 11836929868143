import { Fragment, useState } from "react";
import { useInView } from "react-intersection-observer";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject, seeAlso, Tag } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import { AdUnitNames } from "@components/Advertisement/utils/types";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import {
  getSectionNavigationPath,
  useArticleHeaderState,
} from "@components/Header/helpers";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useArticleGiftReceived from "@hooks/useArticleGiftReceived";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useNextArticle } from "@hooks/useNextArticle";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getFormattedTextForAds } from "@pages/Section/helpers";

import AppBanner from "./components/AppBanner";
import ArticleSubshareModal from "./components/ArticleSubshare/ArticleSubshareModal";
import ArticleSubsharePrompt from "./components/ArticleSubshare/ArticleSubsharePrompt";
import ArticleSubshareReceived from "./components/ArticleSubshare/ArticleSubshareReceived";
import { LAMBDA_RESPONSE_TYPES } from "./types/Alacrity";
import { ArticleFactory, VerticalHeaderFactory } from "./utils/helpers";

type ArticleContentProps = {
  context: ArticleDataObject;
  sectionNews: ArticleDataObject[];
  seeAlso: seeAlso;
  isPreview?: boolean;
};

export default function ArticleContent({
  context,
  sectionNews,
  seeAlso,
  isPreview,
}: ArticleContentProps): React.ReactElement {
  const [showGiftSubscribePrompt, setShowGiftSubscribePrompt] = useState(false);
  const [articleInView, setArticleInView] =
    useState<ArticleDataObject>(context);
  const { isModalOpen, setIsModalOpen, alacrityRes, isGiftReceived } =
    useArticleGiftReceived();

  const { articleTopRef, isShowArticleHeader } = useArticleHeaderState();
  const { isLoading, moreStories, ref } = useNextArticle(context, sectionNews);

  const { gsChannels } = useGrapeshotData(moreStories[0].context.urlPath, -1);

  // Article data de-construct
  const { sections } = context;

  const mainSection = sections?.[0];
  const uniqueName = mainSection?.uniqueName || "";
  const uniqueNameAds = uniqueName?.replace("_", "/") || "";
  const verticalPath = "/" + uniqueNameAds;
  const isThisArticlegifted =
    alacrityRes?.data.responseType ===
      LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN &&
    !alacrityRes.data.viewGiftStatus;

  const VerticalHeaderLayout = VerticalHeaderFactory({ verticalPath });

  const { ref: showGiftSubscribePromptRef } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView && isThisArticlegifted) setShowGiftSubscribePrompt(true);
    },
  });

  const handleSetArticle = (e: ArticleDataObject) => {
    setArticleInView(e);
  };

  const parentPath = articleInView
    ? articleInView.urlPath.replace("/", "")
    : "";

  const parentCategory = parentPath.split("/")[0];

  const sectionPath = getSectionNavigationPath(
    context.tags || [],
    parentCategory
  );

  const disablePrestitialBasedOnUrlPath = [
    RouteFactory.paidPressRelease, // paid press release pages
    RouteFactory.section("events-awards"), // events-awards pages
  ].some((path) => context?.urlPath.startsWith(path));

  const disablePrestitialBasedOnTags = [RouteFactory.keywordsBudget2025].some(
    (tags) => context?.tags?.some((tag) => tag.urlPath === tags)
  );

  const adsNames: AdUnitNames[] =
    disablePrestitialBasedOnUrlPath || disablePrestitialBasedOnTags
      ? ["lb1", "imu1", "imu2", "imu3", "midarticlespecial", "catfish", "abm"]
      : [
          "lb1",
          "imu1",
          "imu2",
          "imu3",
          "midarticlespecial",
          "prestitial",
          "catfish",
          "abm",
        ];

  return (
    <div className="w-full" data-testid="article-content-component">
      <AdSettings
        adNames={adsNames}
        path={`/${uniqueNameAds}`}
        pageType="articles"
        adTargets={[{ key: "page", value: "article" }]}
        slotTargets={[
          { key: "btarticleid", value: context?.id },
          {
            key: "bttags",
            value:
              context?.tags
                ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                .join(",") || "",
          },
          { key: "gs_channels", value: gsChannels },
        ]}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 md:py-3 lg:min-h-[250px]"
      />

      <Header
        article={articleInView}
        displayArticle={isShowArticleHeader}
        parentCategory={sectionPath}
      />

      <PulseEntry />

      <>
        {VerticalHeaderLayout ? (
          <Container rootClassName="py-2">
            <VerticalHeaderLayout />
          </Container>
        ) : null}

        <div ref={articleTopRef} />

        <div id="article-content-wrapper" className="w-full">
          {moreStories.map((article, index) => {
            // Check if article is undefined
            if (!article.context) return null;

            const displayType = article.context.displaySetting?.displayType;
            const ArticleLayout = ArticleFactory({ displayType });

            if (!ArticleLayout) return null;

            return (
              <Fragment key={article.context.id}>
                <ArticleLayout
                  article={article.context}
                  isGiftReceived={isGiftReceived}
                  sectionNews={article.sectionNews}
                  variant="default"
                  index={index}
                  seeAlso={seeAlso}
                  isNavigate={moreStories.length > 1}
                  setArticleObj={(e) => {
                    handleSetArticle(e);
                  }}
                  // isOutbrainEnabled={isOutbrainEnabled}
                  isPreview={isPreview}
                  gsChannels={gsChannels}
                  articleInView={articleInView}
                />
                <div ref={ref}></div>
              </Fragment>
            );
          })}
        </div>

        <ArticleSubsharePrompt
          isModalOpen={showGiftSubscribePrompt}
          setIsModalOpen={setShowGiftSubscribePrompt}
        />

        <div
          aria-roledescription="article-subshare-prompt-observer"
          ref={showGiftSubscribePromptRef}
        ></div>

        {isLoading ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} size="5x" spin className="mb-6" />
          </div>
        ) : null}

        <Ad adType="catfish" />

        <Ad adType="abm" />

        <Ad adType="prestitial" />

        <>
          {alacrityRes ? (
            <ArticleSubshareModal
              isModalOpen={isModalOpen && !alacrityRes.data.viewGiftStatus}
              setIsModalOpen={setIsModalOpen}
            >
              <ArticleSubshareReceived
                alacrityRes={alacrityRes}
                setIsModalOpen={setIsModalOpen}
              />
            </ArticleSubshareModal>
          ) : null}
        </>
      </>

      <AppBanner />
    </div>
  );
}

import { useLocation } from "react-router-dom";
import { PodcastKeywordContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import KeywordFilter from "@components/KeywordFilter";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getFormattedTextForAds, getTermPath } from "@pages/Section/helpers";
import { PODCAST_KEYWORDS_DATA } from "@pages/Section/layouts/PodcastsPage/util/constants";
import { useDetectAdBlock } from "adblock-detect-react";

import PodcastsBanner from "./components/PodcastsBanner";
import PodcastsOverview from "./components/PodcastsOverview";

type PodcastKeywordProps = Pick<PodcastKeywordContext, "data">;

export default function PodcastsKeywordLayout({
  data,
}: PodcastKeywordProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const { pathname } = useLocation();

  const [parentCategory, childCategory] = pathname.replace("/", "").split("/");

  const entity = data?.entity;
  const title = data?.title || "";
  const overview = data?.overview || [];

  const termName = entity?.name || "";
  const termPath = getTermPath(entity) || pathname.replace("/", "");

  const podcastKeyword = PODCAST_KEYWORDS_DATA.find((data) => {
    return data.tag.urlPath === termPath;
  });

  return (
    <div className="w-full" data-testid="podcast-keyword-content-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path="/keywords"
        adTargets={[
          { key: "page", value: "listing" },
          { key: "bttags", value: getFormattedTextForAds(termName) },
        ]}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={`/${termPath}`}
      />

      <GAData
        chapter1={childCategory?.replaceAll("-", " ") || ""}
        level2={parentCategory?.replaceAll("-", " ") || ""}
        title={title}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="py-8">
        <Row>
          <Column rootClassName="w-full mb-9 lg:mb-16">
            <>
              {entity ? (
                <KeywordFilter
                  tag={entity}
                  rootClassName="bg-gray-250 p-3 inline-block mb-6"
                  tagClassName="!text-gray-850"
                />
              ) : null}

              {podcastKeyword ? <PodcastsBanner {...podcastKeyword} /> : null}
            </>
          </Column>

          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <p className="mb-0 border-t border-gray-850 pt-3 font-poppins font-bold uppercase tracking-[0.02em] lg:text-lg">
              episodes
            </p>

            <>
              {podcastKeyword && overview?.length > 0 ? (
                <PodcastsOverview
                  articles={overview}
                  tag={podcastKeyword.tag}
                />
              ) : null}
            </>
          </Column>

          <Column rootClassName="w-full mb-10 px-0 lg:px-3 lg:w-4/12">
            <Ad
              adType="imu2"
              className="flex flex-col items-center justify-center lg:!sticky lg:top-[70px]"
            />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

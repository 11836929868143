import Ad from "@components/Advertisement/Ad";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import { getStatusCodeErrorMessage } from "@util/helpers";

type CustomErrorProps = {
  statusCode?: number;
};

export default function CustomError({
  statusCode = 404,
}: CustomErrorProps): React.ReactElement {
  const errorMessage = getStatusCodeErrorMessage(statusCode);

  return (
    <div className="w-full" data-testid="custom-error-component">
      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container rootClassName="pt-10 pb-14">
        <div className="text-center font-lct text-13xl font-semibold">
          {statusCode}
        </div>

        <h1 className="mb-4 text-center font-lct text-4xl font-bold">
          {`Sorry, this page isn't available.`}
        </h1>

        <p className="text-center font-public-sans text-base font-normal">
          {errorMessage}
        </p>
      </Container>
    </div>
  );
}

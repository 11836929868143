import { SanitizedSectionArticle } from "@app/types/Cue";
import LoadMore from "@components/LoadMore/LoadMore";
import { useLoadMorePodcastStories } from "@hooks/useLoadMorePodcastStories";

import MorePodcastsStories from "./MorePodcastsStories";

type MorePodcastsProps = {
  articles: SanitizedSectionArticle[];
};

export default function MorePodcasts({
  articles,
}: MorePodcastsProps): React.ReactElement {
  const { articleList, handleLoadMore, hasMoreArticles } =
    useLoadMorePodcastStories(articles, 10);

  return (
    <section data-testid="more-podcasts-component">
      <div className="stories divide-y divide-gray-175">
        {articleList.map((article, index) => {
          return (
            <MorePodcastsStories key={article.id} {...{ article, index }} />
          );
        })}
      </div>

      {hasMoreArticles ? (
        <LoadMore
          rootClassName="mt-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreArticles}
        />
      ) : null}
    </section>
  );
}

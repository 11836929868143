import { Link } from "react-router-dom";
import BudgetLogo2025 from "@assets/budget/logo-2025-v1.png";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import { NEWS_BAR_SECTION, NEWS_BAR_SECTION_URL } from "./utils/constants";
import type { NewsBarPropsMobile } from "./utils/types";

export default function NewsBarMobile({
  className,
}: NewsBarPropsMobile): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  const handleOnClickArticlesAndKeywords = () => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "click",
      eventLabel: "news bar",
    });
  };

  return (
    <div className={className} data-testid="news-bar-mobile-component">
      <div className="flex items-center justify-between py-3">
        <img
          src={BudgetLogo2025}
          alt="bugdet 2025 logo"
          width={85}
          height={34}
          className="mr-3 border-r border-blue-100 pr-3"
        />

        <Link
          to={NEWS_BAR_SECTION_URL}
          onClick={handleOnClickArticlesAndKeywords}
          className="group flex-none"
        >
          <p className="m-0 font-poppins text-3xs font-semibold -tracking-2% text-blue-200">
            {NEWS_BAR_SECTION}
          </p>
          <p className="font-poppins text-4xs tracking-2% text-blue-200">
            Read our coverage
          </p>
        </Link>
      </div>
    </div>
  );
}

import { fetchShortUrl } from "@app/pages/Article/Article.server";
import { ArticleDataObject } from "@app/types/Cue";
import { ENVIRONMENT } from "@util/constant";
import { getEnvironmentDomain } from "@util/helpers";
import axios from "axios";

export const UseFetchNextArticle = async (lastArticle?: ArticleDataObject) => {
  try {
    if (!lastArticle) return;

    const response = await axios.post("/_plat/api/v1/next-article", {
      category: lastArticle?.sections?.[0].uniqueName, // "companies-markets_banking-finance" || "food-drink"
      sort: lastArticle?.sort, // [1703168467000, "2166527"]
    });

    const nextStory = response.data;
    if (typeof nextStory.context.urlPath === "undefined") return;
    const longURL = `${getEnvironmentDomain(ENVIRONMENT)}${nextStory.context.urlPath}`;
    const shortUrl = await fetchShortUrl(longURL);
    const nextStoryWShortUrl = {
      ...nextStory,
      shortUrl: shortUrl || longURL,
    };

    return nextStoryWShortUrl;
  } catch (error) {
    return [];
  }
};

import { useEffect, useState } from "react";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { Transition, TransitionChild } from "@headlessui/react";
import { useDelayedComponents } from "@hooks/useDelayedComponents";
import useSendPulseGaData from "@pages/Pulse/hooks/useSendPulseGaData";
import Cookies from "js-cookie";

export default function PulseEntryToolTip(): React.ReactElement {
  const [isVisited, setIsVisited] = useState(false);
  const { isShown, handleSetIsShown, isTimeout } = useDelayedComponents(5000);
  const { sendCustomEvent } = useSendPulseGaData();

  const handlePulseTooltipVisibility = () => {
    handleSetIsShown(false);
    sendCustomEvent("click", "tooltip");
  };

  useEffect(() => {
    const cookie = Cookies.get("BTPulseTooltip");

    if (typeof cookie !== "undefined") setIsVisited(true);
  }, []);

  useEffect(() => {
    if (!isTimeout) return;

    Cookies.set("BTPulseTooltip", "shown");
  }, [isTimeout]);

  return (
    <div data-testid="pulse-entry-tooltip-component">
      <Transition show={!isVisited && isShown}>
        <TransitionChild
          enter="transition duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative pb-5 transition-all duration-300">
            <div className="flex max-w-[295px] items-center justify-center rounded-md bg-gray-850 p-3 text-white">
              <p className="mb-0 ml-2 font-poppins text-xs font-medium tracking-2%">
                Quickly catch up on the news with our bite-sized summaries
              </p>

              <Icon
                className="flex-none cursor-pointer"
                type={IconTypeEnum.close}
                fill="white"
                onClick={handlePulseTooltipVisibility}
              />
            </div>
            <div className="absolute right-12 h-0 w-0 border-l-[10px] border-r-[10px] border-t-[15px] border-l-transparent border-r-transparent border-t-gray-850"></div>
          </div>
        </TransitionChild>
      </Transition>
    </div>
  );
}

import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import Container from "@src/app/components/Container/Container";
import { useDetectAdBlock } from "adblock-detect-react";

import {
  ANNOUNCEMENT_URL,
  CUSTOM_OPTION_URL,
  DEDICATED_SECTION_URL,
  FAQ_PAGE_METATAGS,
  MAIL_TO_TIA_FAQ,
  PR_PAGE_URL,
  TIA_PR_PAGE_URL,
} from "./constants";

export default function FaqPage(): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  const metaTagsProps = FAQ_PAGE_METATAGS;

  return (
    <div className="w-full" data-testid="faq-page-component">
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.faq}
      />
      <MetaTags {...metaTagsProps} />

      <GAData
        title="Faq"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container rootClassName="py-6 font-poppins">
        <h2
          className="mb-4 mt-1 text-5xl font-medium underline lg:text-6xl"
          id="chapter1"
        >
          Paid Press Release Publication Guidelines
        </h2>

        <p className="mb-0">
          To ensure a smooth and quick publication process, please adhere to the
          following guidelines:
        </p>

        <ul className="mb-5 list-disc pl-7">
          <li>Keep headlines under 90 characters</li>
          <li>Keep excerpts under 150 characters</li>
          <li>Keep press release copy under 1,000 words</li>
          <li>
            Ensure that your accompanying image is 1920px by 1080px and in .JPG
            file format
          </li>
          <li>
            {
              "Ensure that your company's logo is 90px in height and in .PNG file format"
            }
          </li>
          <li>
            Ensure backlinks lead to working URLs. There is a cap of up to 2
            backlinks.
          </li>
        </ul>

        <p className="mb-0">
          For first-time press release writers, here are some top tips to help
          make sure your news is read:
        </p>

        <ul className="mb-5 list-disc pl-7">
          <li>
            Create an attention-grabbing headline that conveys the main point of
            your story
          </li>
          <li>
            {
              "Begin with a brief introduction that answers the 5 Ws (who, what, where, when, and why) to provide important details to readers"
            }
          </li>
          <li>
            Provide additional details in a simple and straightforward manner,
            arranged in order of importance
          </li>
          <li>
            Avoid writing in the first person or using hyperbolic language, and
            be honest and unbiased when presenting the facts
          </li>
          <li>
            Avoid using industry jargon that may be difficult for the average
            person to understand
          </li>
          <li>
            Include relevant and colourful quotes to add personality to the
            press release, ensuring to attribute quotes to their source
          </li>
          <li>
            Check out
            <Link
              to={ANNOUNCEMENT_URL}
              target="_blank"
              className="italic text-verticals-btblue underline hover:text-black hover:underline"
              rel="noopener noreferrer"
            >
              {" this announcement by Tech in Asia "}
            </Link>
            {" for an example of what a press release should read like"}
          </li>
          <li>
            Need a helping hand from us? Explore
            <Link
              to={CUSTOM_OPTION_URL}
              target="_blank"
              className=" text-verticals-btblue underline hover:text-black hover:underline"
              rel="noopener noreferrer"
            >
              {" customised options here"}
            </Link>
          </li>
        </ul>

        <h2 className="mb-2 mt-1 text-5xl font-medium underline lg:text-6xl">
          Frequently Asked Questions
        </h2>

        <h3 className="text-base font-bold">
          What are the processes involved after I have submitted my press
          release?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            {
              "Once you've submitted your press release and made payment, our staff will review your submission to ensure that it adheres to our press release publication guidelines. If your submission is approved, you'll receive an email within 1-2 business days with a link to your published paid press release. Should your release not be approved, you will receive a refund within that same timeframe."
            }
          </li>
        </ul>

        <h3 className="text-base font-bold">
          Where will my paid press release be published?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            {"Your paid press release will be published in a "}
            <Link
              to={DEDICATED_SECTION_URL}
              target="_blank"
              className=" text-verticals-btblue underline hover:text-black hover:underline"
              rel="noopener noreferrer"
            >
              dedicated section
            </Link>
            {" of The Business Times website"}
          </li>
        </ul>

        <h3 className="text-base font-bold">
          <strong>What will my published paid press release look like?</strong>
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {"You may visit "}
              <Link
                to={PR_PAGE_URL}
                target="_blank"
                className=" text-verticals-btblue underline hover:text-black hover:underline"
                rel="noopener noreferrer"
              >
                Paid Press Releases
              </Link>
              {
                " to view your published press release. For transparency and full disclosure to our readers, all releases will be accompanied by the following note:"
              }
            </p>
            <blockquote className="my-3 border-l-2 border-gray-300 p-2">
              <p>
                {
                  "This press release was published as it was received from our partner, without any editing from The Business Times (BT) team. The views expressed in this release are those of our partners and do not reflect BT’s opinions and beliefs."
                }
              </p>
            </blockquote>
          </li>
        </ul>

        <h3 className="text-base font-bold" id="chapter2">
          What topics should my paid press release be about to get approved for
          publication?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            {
              "The content of your press release should appeal to BT's audience,which includes business leaders, investors, startup founders and professionals, both in Singapore and the region. Potential topics range from product launches or events or changes in top management to funding announcements or business expansion. Do note that press releases promoting illegal content or services will not be accepted."
            }
          </li>
        </ul>

        <h3 className="text-base font-bold">
          How long will it take to review and approve my press release for
          publication?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>{"One to two business days"}</li>
        </ul>

        <h3 className="text-base font-bold">
          Can I schedule my press release for publication on a future date?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            {
              "Yes. Simply indicate the date and time that you wish for the press release to be published in the "
            }
            <Link
              to={TIA_PR_PAGE_URL}
              target="_blank"
              className=" text-verticals-btblue underline hover:text-black hover:underline"
              rel="noopener noreferrer"
            >
              submission form on this page.
            </Link>
          </li>
        </ul>

        <h3 className="text-base font-bold">
          Can I update or remove the information on my press release at a later
          date?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {
                "If there is a factual error in your press release that requires amending, please e-mail "
              }
              <Link
                to={`mailto:${MAIL_TO_TIA_FAQ}`}
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                {MAIL_TO_TIA_FAQ}
              </Link>
              {
                " with the subject “Press Release Amendment: [Your Published Press Release Headline]” and explain the amendment that needs to be made."
              }
            </p>
          </li>
          <li>
            <p>
              {
                " If you wish to remove a published press release from our site,simply e-mail "
              }
              <Link
                to={`mailto:${MAIL_TO_TIA_FAQ}`}
                target="_blank"
                className="text-verticals-btblue hover:text-black hover:underline"
                rel="noreferrer"
              >
                {MAIL_TO_TIA_FAQ}
              </Link>
              {
                " with the subject “Press Release Retraction:[Your Published Press Release Headline]” and our team will address your query on a case-by-case basis. Please note that no refunds will be given on "
              }
              <strong>any</strong>
              {" press release that"} <strong>has</strong>{" "}
              {"been removed from the BT website after publication."}
            </p>
          </li>
        </ul>

        <h3 className="text-base font-bold">
          What payment methods do you accept?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {
                " You can make payment by debit or credit card. Please contact our team at  "
              }
              <Link
                to={`mailto:${MAIL_TO_TIA_FAQ}`}
                target="_blank"
                className="text-verticals-btblue underline hover:text-black hover:underline"
                rel="noreferrer"
              >
                {MAIL_TO_TIA_FAQ}
              </Link>
              {
                " should you wish to make a bank transfer. Do take note that we will only review submissions that have been paid for. "
              }
            </p>
          </li>
        </ul>

        <h3 className="text-base font-bold">
          Can the links in my press release be configured as follow links?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {
                " BT's website has a default no-follow setting applied to all links within our published articles. There will be no exception to this rule. "
              }
            </p>
          </li>
        </ul>

        <h3 className="text-base font-bold">
          Can I upload multiple images to accompany my press release?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {
                "Published paid press releases on the BT website will be accompanied by a single image. Submissions with multiple images will not be accepted."
              }
            </p>
          </li>
        </ul>

        <h3 className="text-base font-bold">
          {
            "Will there be a report on my paid press release's performance after publication?"
          }
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {
                "The paid press release publication service does not include post-campaign performance reporting."
              }
            </p>
          </li>
        </ul>

        <h3 className="text-base font-bold">Can I get a refund?</h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            <p>
              {
                "If you are seeking a refund on your purchase of our paid press release service, please e-mail <"
              }
              {MAIL_TO_TIA_FAQ}
              {
                "> with the subject “Press Release Refund: [Your Published Press Release Headline]” and our team will address your query on a case-by-case basis."
              }
            </p>
          </li>
        </ul>

        <h3 className="text-base font-bold">
          Why was my press release submission rejected?
        </h3>

        <ul className="mb-5 list-disc pl-7">
          <li>
            There are two main reasons for which press release submissions are
            rejected:
          </li>
        </ul>

        <ol className="mb-5 list-decimal pl-7">
          <li>
            <p className="mb-0">Format</p>
            <ul className="mb-5 list-disc pl-7">
              <li>
                Our team reviews each press release submission to ensure that
                the comply with our{" "}
                <Link
                  to={"#chapter1"}
                  className=" text-verticals-btblue underline hover:text-black hover:underline"
                >
                  submission guidelines.
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <p className="mb-0">Content</p>
            <ul className="mb-3 list-disc pl-7">
              <li>
                Your press release concerns topics that are not of interest to
                our BT readers. Please refer to our{" "}
                <Link
                  to={"#chapter2"}
                  className=" text-verticals-btblue underline hover:text-black hover:underline"
                >
                  content recommendations
                </Link>{" "}
                for more details.
              </li>
            </ul>
          </li>
        </ol>

        <p>
          {"If you have any other questions, please email "}
          <Link
            to={`mailto:${MAIL_TO_TIA_FAQ}`}
            target="_blank"
            className="text-verticals-btblue hover:text-black hover:underline"
            rel="noreferrer"
          >
            {MAIL_TO_TIA_FAQ}
          </Link>
          {" with your query."}
        </p>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

import { memo, ReactElement } from "react";
import { getIconSrc } from "@components/Button/helpers";
import { IconName } from "@components/Button/types";
import useSendPulseGaData from "@pages/Pulse/hooks/useSendPulseGaData";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";
import { cn } from "@util/helpers";

export type PulseIndividualMobileArticleProps = {
  article: SanitizedPulseData;
  testId?: string;
};

function PulseIndividualMobileArticle({
  article,
  testId = "pulse-mobile-individual-article-card-component",
}: PulseIndividualMobileArticleProps): ReactElement {
  const { sendCustomEvent } = useSendPulseGaData();

  const shareOnClick = () => {
    if (typeof navigator.share === "function" && article.shareUrl) {
      sendCustomEvent("share", article.title);

      navigator.share({
        text: article.title,
        url: article.shareUrl,
      });
    }
  };

  const handleOnClickReadMore = () => {
    sendCustomEvent("read full story", article.title);
  };

  return (
    <div
      data-testid={testId}
      className={cn(
        "relative flex h-full w-screen flex-col items-center justify-center rounded-md transition-all duration-700"
      )}
    >
      <div className="mx-auto flex w-full max-w-[500px] flex-1 grow flex-col items-center justify-center self-stretch px-3 py-0">
        <div className="relative flex min-h-[500px] w-full flex-col items-start justify-between self-stretch overflow-hidden rounded-md bg-black/60 backdrop-blur-sm">
          <div className="p-3">
            <div>
              <div className="flex items-center justify-between">
                <p
                  className="mb-0 pb-2 font-poppins text-4xs font-medium uppercase tracking-widest text-white"
                  data-testid="individual-article-card-section"
                >
                  {article.sections}
                </p>

                <p
                  className="mb-0 ml-2 flex-none pb-2 font-poppins text-4xs font-light uppercase tracking-widest text-white"
                  data-testid="individual-article-card-disclaimer"
                >
                  AI-generated
                </p>
              </div>

              <p
                className="pb-2 font-lct text-xl font-bold leading-tight -tracking-2% text-white"
                data-testid="individual-article-card-title"
              >
                {article.title}
              </p>
            </div>

            <ul className="max-h-[300px] list-outside list-disc space-y-1 overflow-y-scroll pb-16 pl-6 font-lucida leading-normal -tracking-5% text-gray-250 scrollbar">
              {article.summaries.map((summary, index) => {
                return (
                  <li key={`summary-${article.id}-${index}`}>{summary}</li>
                );
              })}
            </ul>
          </div>

          <div className="absolute bottom-0 flex h-[100px] w-full items-end justify-end bg-gradient-to-t from-black from-40% to-90%">
            <div className="mx-4 flex h-max w-full items-center justify-between py-4 transition-all duration-300">
              <button
                onClick={shareOnClick}
                className="tracking-tight inline-flex items-center gap-2 font-poppins text-sm font-semibold leading-tight text-white"
                aria-label={`share-${article.id}`}
              >
                <img
                  src={getIconSrc(IconName.share)}
                  width={36}
                  height={36}
                  alt="close"
                />
              </button>

              <a
                href={`${article.url}?ref=pulse`}
                target="_blank"
                className="tracking-tight inline-flex items-center gap-2 font-poppins text-sm font-semibold leading-tight text-white"
                rel="noreferrer"
                aria-label={`link-${article.id}`}
                onClick={handleOnClickReadMore}
              >
                Read full story
                <img
                  src={getIconSrc(IconName.link)}
                  width={24}
                  height={24}
                  alt="exit"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PulseIndividualMobileArticle);

import { OKTARegistrationStatus } from "@store/useOKTAUserStore";
import {
  mySPHOpenLogout,
  mySPHOpenResendVerificationMail,
  mySPHOpenUpdatePassword,
} from "@util/helpers";

import { UserInfoProps } from "./UserInfo";

export default function UserInfoPanel({
  userInfo,
  closePanel,
}: UserInfoProps): React.ReactElement {
  const handleResendVerificationMail = () => {
    mySPHOpenResendVerificationMail();
    if (closePanel) closePanel();
  };

  const handleUpdatePassword = () => {
    mySPHOpenUpdatePassword();
    if (closePanel) closePanel();
  };

  const handleLogout = () => {
    mySPHOpenLogout();
    if (closePanel) closePanel();
  };

  return (
    <div data-testid="user-info-panel-component">
      {userInfo?.reguserstatus === OKTARegistrationStatus.UNVERIFIED ? (
        <div
          onClick={handleResendVerificationMail}
          className="bg-gray-850 py-4 text-center font-poppins text-2xs font-medium tracking-2% text-white"
        >
          Please verify your email
        </div>
      ) : null}

      <div className="mx-5 mb-2 cursor-default border-b border-gray-175 py-1 pt-5 text-xs font-semibold uppercase leading-normal text-gray-350">
        Welcome
        <span className="word-break ml-1">{userInfo?.display_name}</span>
      </div>

      <div className="cursor-pointer px-5 py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 lg:hover:bg-gray-175">
        <a
          target="_blank"
          href={`https://subscribe.sph.com.sg/your-account/?utm_campaign=manageaccount&utm_medium=sph-publication&utm_source=bt&utm_content=manageaccountlink-dropdown&pub-code=bt${encodeURIComponent(
            location.href
          )}`}
          rel="noreferrer"
        >
          Manage Account
        </a>
      </div>

      <div
        className="cursor-pointer px-5 py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 lg:hover:bg-gray-175"
        onClick={handleUpdatePassword}
      >
        Update your password
      </div>

      <div
        className="cursor-pointer px-5 py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 lg:hover:bg-gray-175"
        onClick={handleLogout}
      >
        Logout
      </div>
    </div>
  );
}

import type { NavigationItem } from "@app/types/Navigation";
import { sectionNavigationItems } from "@util/constant";

const navigationItems: NavigationItem[] = [
  {
    ...sectionNavigationItems["keywords/singapore-budget-2025"],
    label: "Budget 2025",
    children: [],
    extraClass: "text-blue-200",
    trackingData: {
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "click",
      eventLabel: "nav bar",
    },
  },
  { ...sectionNavigationItems["breaking-news"], label: "Breaking" },
  sectionNavigationItems.singapore,
  sectionNavigationItems.international,
  { ...sectionNavigationItems["companies-markets"], label: "Companies" },
  sectionNavigationItems.property,
  { ...sectionNavigationItems["startups-tech"], label: "Startups" },
  { ...sectionNavigationItems["opinion-features"], label: "Opinion" },
  sectionNavigationItems.lifestyle,
];

const navigationItemsRight: NavigationItem[] = [
  sectionNavigationItems.myBT,
  sectionNavigationItems.newsletter,
  { ...sectionNavigationItems.epaper, label: "Epaper" },
];

export { navigationItems, navigationItemsRight };

export const navigationConstant = [
  {
    section: {
      key: "navigation-left",
      children: navigationItems.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
  {
    section: {
      key: "navigation-right",
      label: "Section",
      children: navigationItemsRight.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
];

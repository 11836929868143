import type { TermContext } from "@app/types/Page";

export type SectionDefaultProps = {
  parentCategory?: string;
  childCategory?: string;
} & Pick<TermContext, "data">;

export enum TagType {
  StoryThread = "storyThread",
  Keyword = "keyword",
}

import { RefObject, useRef, useState } from "react";
import { Await } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { NewsletterArchiveKeywordsEnums } from "@app/types/enums";
import { NewsletterArchiveContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BIGMONEY_CONTRIBUTORS,
  BIGMONEY_DESC,
  BIGMONEY_TITLE,
} from "@components/Newsletter/constants";
import HomepageCarouselNewsletter from "@components/Newsletter/HomepageCarouselNewsletter";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import BigMoneySampleIssue from "@pages/Newsletter/Archive/BigMoney/SampleIssues/BigMoneySampleIssue";
import NewsletterArchiveArticlesList from "@pages/Newsletter/Archive/components/NewsletterArchiveArticlesList";
import NewsletterArchiveHeader from "@pages/Newsletter/Archive/components/NewsletterArchiveHeader";
import SocialShare from "@pages/Newsletter/Archive/components/SocialShare";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { ENVIRONMENT } from "@util/constant";
import { getEnvironmentDomain } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import { HttpStatusCode } from "axios";

const bigMoneyNL = BT_NEWSLETTERS.filter(
  ({ type }) => type === NEWSLETTER_TYPE.BigMoney
);

export default function NewsletterBigMoneyArchivePage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<NewsletterArchiveContext, string>>();

  const adBlockDetected = useDetectAdBlock();
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const divRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [isKeepReadingVisible, setKeepReading] = useState(true);

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  const baseUrl = getEnvironmentDomain(ENVIRONMENT);
  const payload = context.payload;
  const { data } = payload;

  const handleButtonClick = () => {
    if (divRef?.current) {
      divRef.current.classList.add("overflow-visible", "h-full");
      if (divRef.current.firstChild) {
        (divRef.current.firstChild as HTMLElement).classList.add(
          "overflow-visible",
          "h-full"
        );
      }

      setKeepReading(false);
    }
  };

  return (
    <div
      className="w-full"
      data-testid="newsletter-big-monney-archive-page-component"
    >
      <AdSettings
        adNames={["lb1", "imu1", "catfish", "abm"]}
        path={RouteFactory.newsletterBigMoneyArchivePage}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={data.title}
        description="Read more at The Business Times."
        slug={RouteFactory.newsletterBigMoneyArchivePage}
      />

      <GAData
        title="big_money"
        level2="newsletter"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container rootClassName="pt-5 !px-5">
        <div className="border-b-2 border-black">
          <NewsletterArchiveHeader
            newsletterTitle={BIGMONEY_TITLE}
            newsletterDescription={BIGMONEY_DESC}
            newsletterContributors={BIGMONEY_CONTRIBUTORS}
            isBigMoney={true}
            newsletterRoute={RouteFactory.newsletterBigMoneyArchivePage}
            isSubscriber={isSubscriber}
            newsletterTime={"Monday, 7 am"}
            signUpNL={bigMoneyNL[0]}
            divRef={divRef}
          />
        </div>

        <Await resolve={context.payload} errorElement={<CustomError />}>
          {({ kind, data }) => {
            if (kind !== "newsletter") {
              // Error or kind is not "art"
              return <CustomError statusCode={context.statusCode} />;
            }

            // StatusCode is 200 and kind is "art"
            return (
              <NewsletterArchiveArticlesList
                newsletterKeyword={NewsletterArchiveKeywordsEnums.BigMoney}
                articles={data.overview}
              />
            );
          }}
        </Await>

        <div className="relative flex flex-col lg:hidden">
          <BigMoneySampleIssue divRef={divRef} rootClassName="h-[555px]" />

          {isKeepReadingVisible ? (
            <div className="absolute bottom-0 mb-[1px] ml-4 w-11/12 bg-nlArchiveGradient px-4 pb-4 pt-20">
              <button
                className="flex w-full flex-row
                items-start justify-center rounded-3xl border border-gray-850 py-3 lg:hidden"
                onClick={handleButtonClick}
              >
                KEEP READING
              </button>
            </div>
          ) : null}
        </div>

        <SocialShare
          shareUrl={baseUrl + RouteFactory.newsletterBigMoneyArchivePage}
          rootClassName={"pt-12 block lg:hidden pb-20"}
        />

        <HomepageCarouselNewsletter
          newsletterToSkip={NEWSLETTER_TYPE.BigMoney}
          showSeeMore={false}
          blockTitle="YOU MAY LIKE"
          rootClassName="border-t border-black pt-3 mb-16"
        />
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

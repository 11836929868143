import { Helmet } from "react-helmet-async";
import { Kind } from "@app/data";
import { KeyValuePair } from "@app/types/Page";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import { IS_PLAYWRIGHT } from "@util/constant";

import { SMX_PREBID_JS } from "./utils/constants";
import { getAdUnitData } from "./utils/helpers";
import { AdUnitNames } from "./utils/types";
import headCssString from "./AdSettings.head.css?raw";

type AdSettingsProps = {
  adNames: AdUnitNames[];
  path: string;
  adTargets?: KeyValuePair<string, string>[]; // page level ad targets
  slotTargets?: KeyValuePair<string, string>[]; // slot level ad targets
  pageType?: Kind;
};

export default function AdSettings({
  adNames,
  path,
  adTargets = [], // page level ad targets
  slotTargets = [], // slot level ad targets
  pageType,
}: AdSettingsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  // Disable ads in playwright.
  if (IS_PLAYWRIGHT) {
    return (
      <Helmet>
        <script>{`(${overlaySEF.toString()})()`}</script>
      </Helmet>
    );
  }

  // If initial gsChannels is empty string, do not render the ad settings.
  const gsChannels = slotTargets.find(
    (target) => target.key === "gs_channels"
  )?.value;

  if (gsChannels === "") {
    return (
      <Helmet>
        <style type="text/css">{headCssString}</style>
      </Helmet>
    );
  }

  const adUnits = getAdUnitData(adNames, path, slotTargets);
  const pageData = {
    pageType: pageType,
    path: path,
    adUnits: adUnits,
    adTargets: adTargets,
    userInfo: OKTAUserInfo,
    smxPrebidJs: SMX_PREBID_JS,
  };
  const loaded_dfp_tags = adUnits.reduce((previous, current) => {
    return {
      ...previous,
      [current.type]: {
        adunit: current.pattern,
        slotname: current.id,
        size: Object.keys(current.slotSize)
          .map((key) => {
            const sizes = current.slotSize?.[parseInt(key)];

            return sizes?.toString().replaceAll(",", "x");
          })
          .join(","),
      },
    };
  }, {});

  return (
    <Helmet>
      <script
        type="text/javascript"
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        async
      />

      <script>{`${[
        "var googletag = googletag || {};",
        "googletag.cmd = googletag.cmd || [];",
        "googletag.slots = googletag.slots || {};",
        "window.bt_page_data = " + JSON.stringify(pageData) + ";",
        "var loaded_dfp_tags = '" + JSON.stringify(loaded_dfp_tags) + "';",
      ].join(" ")}(${overlaySEF.toString()})()`}</script>

      <style type="text/css">{headCssString}</style>

      <script type="text/javascript" src="/_web2/betterads_head.js"></script>

      <script
        type="text/javascript"
        src="https://dsuwzj1tch87b.cloudfront.net/suid/suid.min.js"
      ></script>

      <script
        type="text/javascript"
        src="https://adtag.sphdigital.com/tag/smx/prebid.js"
      ></script>

      <script type="text/javascript" src={SMX_PREBID_JS}></script>

      <script type="text/javascript" src="/_web2/bt_ads.js" async></script>

      <script type="text/javascript" src="/_web2/betterads_head2.js"></script>
    </Helmet>
  );
}

// a self executing function injected into the head
const overlaySEF = function () {
  const htmlClassList = document.documentElement.classList;
  const myPageOverlay = document.getElementById("myPageOverlay");

  if (
    screen.width >= 1000 &&
    (!document.cookie.includes("prestitialDisplayed=yes") ||
      window.location.search.includes("dfp_preview"))
  ) {
    setTimeout(function () {
      if (!htmlClassList.contains("gotprestitial")) {
        myPageOverlay?.classList.remove("overlayWhite");
      }
    }, 4e3);
  }
};

import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import type { ArticleDataObject } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import LoadMore from "@components/LoadMore/LoadMore";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import { compact } from "lodash-es";

import SectionListingStories from "./SectionListingStories";

export type OverviewProps = {
  testId?: string | null;
  data?: ArticleDataObject[];
  slot?: string;
};

const PAGE_SIZE = 10;

export default function Overview({
  testId = null,
  data = [],
}: OverviewProps): React.ReactElement {
  const matchedCategory = useLocation().pathname.replace("/", "");
  const [parentCategory = "", childCategory = ""] = matchedCategory.split("/");
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [loadMoreData, setLoadMoreData] = useState<ArticleDataObject[]>([]);

  //compact removes all falsey values
  const uniqueCategoryQuery = compact([
    parentCategory !== "keywords" && parentCategory,
    childCategory,
  ]).join("_");

  const handleLoadMore = async () => {
    const page = [...data, ...loadMoreData].length / PAGE_SIZE + 1;

    const moreArticles = await fetchMoreStoriesData(
      uniqueCategoryQuery,
      parentCategory,
      page
    );

    setLoadMoreData([...loadMoreData, ...moreArticles]);

    if (moreArticles.length < PAGE_SIZE) {
      setHasMoreStories(false);
    }
  };

  return (
    <section data-testid={testId}>
      <div className="stories">
        {[...data, ...loadMoreData].map(
          (article: ArticleDataObject, index: number) => {
            return (
              <Fragment key={article.id}>
                {index === 5 ? (
                  <Ad
                    adType="bn1"
                    className="flex flex-col items-center justify-center border-b border-gray-175 py-3"
                  />
                ) : null}

                <SectionListingStories
                  key={index}
                  index={index}
                  article={article}
                />
              </Fragment>
            );
          }
        )}
      </div>

      {data.length === 10 ? (
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreStories}
        />
      ) : null}
    </section>
  );
}

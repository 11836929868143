import { useState } from "react";
import { ArticleDataObject } from "@app/types/Cue";
import axios from "axios";

const articlesToDisplay = 10;

export const useLoadMoreArticlesByProfileId = (
  profileId: string,
  initialList: ArticleDataObject[]
) => {
  const [articleList, setArticleList] =
    useState<ArticleDataObject[]>(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length === articlesToDisplay
  );

  const handleLoadMore = async () => {
    if (initialList.length === 0) return;

    const response = await axios.post<ArticleDataObject[]>(
      "/_plat/api/v1/more-stories-by-profile-id",
      {
        profileId: profileId,
        sort: articleList?.[articleList.length - 1]?.sort,
      }
    );

    const newMoreArticles = response.data || [];

    if (newMoreArticles.length < articlesToDisplay) setHasMoreArticles(false);

    setArticleList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};

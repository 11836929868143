/**
 * Company: SPHMedia
 * Description: SME Latest Layout
 */

import { ReactElement, useState } from "react";
import { RouteFactory } from "@app/routePaths";
import type { SanitizedSectionArticle } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import LoadMore from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import VerticalBlockTitle from "@components/VerticalBlockTitle/VerticalBlockTitle";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import type { SectionDefaultProps } from "@pages/Section/types";

import SMEBanner from "./components/SMEBanner";
import SMENewsletterDescription from "./components/SMENewsletterDescription";

const variant: CardVariations = {
  image: {
    position: "right",
    width: "w-4/10 md:w-2/10 lg:w-3/20",
    extraClass: "order-3",
  },
  content: {
    width: "w-6/10 md:w-8/10 lg:17/20",
    extraClass: "pr-4",
    blurb: {
      extraClass: "!font-poppins",
    },
  },
  kicker: {
    color: "text-gray-515",
    extraClass: "font-poppins text-4xs",
  },
  title: {
    color: "!text-gray-850",
    size: "text-xs",
    extraClass:
      "font-poppins font-semibold !leading-normal hover:text-verticals-sme-hover",
  },
};

const PAGE_SIZE = 10;

export default function SMEVerticalLatest({
  data: { title, overview, urlPath },
}: SectionDefaultProps): ReactElement {
  const [latestStories, setLatestStories] =
    useState<SanitizedSectionArticle[]>(overview);
  const [hasMoreStories, setHasMoreStories] = useState(true);

  const handleLoadMore = async () => {
    const page = latestStories.length / PAGE_SIZE + 1;
    const moreStories = await fetchMoreStoriesData(
      urlPath.replace(/^\/+/, "").replaceAll("/", "_").replace("_latest", ""),
      "",
      page
    );
    if (moreStories.length < PAGE_SIZE) setHasMoreStories(false);
    setLatestStories([...latestStories, ...moreStories]);
  };

  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.globalEnterprise}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        ogType="article"
        slug={`${RouteFactory.sgsme}/latest`}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="py-4">
        <SMEBanner />

        <VerticalBlockTitle rootClassName="mb-4" text="Latest" />
        <ul
          data-testid="sme-vertical-latest-list"
          className="flex flex-col divide-y"
        >
          {latestStories.map((article) => {
            const uniqueName = article.sections?.[0]?.uniqueName;

            if (!uniqueName) return null;

            return (
              <li className="py-4" key={article.id}>
                <BasicCard
                  rootClassName="text-gray-850"
                  id={article.id}
                  slug={article.urlPath}
                  media={article.media}
                  title={
                    article.displaySetting?.displayHeadline || article.title
                  }
                  kicker={article.kicker?.fields?.[0]?.value}
                  blurb={article.blurb}
                  paywall={article.paywall?.contentAccess === "1"}
                  variations={variant}
                  created={article.created}
                  edited={article.edited}
                  updated={article.updated}
                  hasUpdatedTimestampDisplay={
                    article.displaySetting?.hasUpdatedTimeDisplayed
                  }
                  defaultImage={{ directoryName: uniqueName }}
                />
              </li>
            );
          })}
        </ul>

        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreStories}
        />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<SMENewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) => newsletter.type === NEWSLETTER_TYPE.SGSME
        )}
        btnColors="bg-verticals-sme text-white hover:bg-white hover:text-verticals-sme"
        linkColors="text-verticals-sme"
      />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}

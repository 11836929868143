/**
 * Company: SPHMedia
 * Description: Lifestyle Section Layout
 */

import { Await, Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { LifestyleContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import { CardVariations } from "@components/ArticleCard/types";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import {
  useBreadCrumbState,
  useCategoriesFromPath,
} from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import BtLuxeCarousel from "@components/Slider/BtLuxeSlider";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import LifestyleHero from "@pages/Section/components/Lifestyle/LifestyleHero";
import LifestyleNews from "@pages/Section/components/Lifestyle/LifestyleNews";
import SectionDivider from "@pages/Section/components/Lifestyle/SectionDivider";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { useContext } from "@util/getContext";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
/**
 * Lifestyle
 * Description: Lifestyle Section Layout
 * @param {SectionProps} props
 * @returns {SectionContext}
 */
export type SectionProps = {
  context: LifestyleContext;
};

export default function LifestylePage({
  testId,
}: {
  testId?: string;
}): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const context: LifestyleContext = useContext();
  const adBlockDetected = useDetectAdBlock();
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const { parentCategory, childCategory } = useCategoriesFromPath();

  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-full lg:w-1/2",
      extraClass: "object-cover",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 588, h: 392, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 696, h: 464, dpr: 1, f: "webp" },
        { w: 401, h: 267, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-full lg:w-1/2",
      extraClass: "p-4 bg-gray-850 text-white",
    },
    title: {
      size: "text-xl lg:text-6xl",
      color: "text-white",
      extraClass: "text-left font-pt-serif",
    },
    kicker: {
      color: "text-gray-515",
      extraClass: "uppercase",
    },
  };

  return (
    <Await resolve={context} errorElement={<CustomError />}>
      {({ kind, error, data: { title, lifestyle, lifestyleCarousel } }) => {
        return kind !== "section" || error ? (
          <h1>Error</h1>
        ) : (
          <div className="w-full" data-testid={testId}>
            <AdSettings
              adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
              path={RouteFactory.lifestyle}
              adTargets={[{ key: "page", value: "listing" }]}
            />

            <MetaTags
              title={title}
              description="THE BUSINESS TIMES Lifestyle - Find Lifestyle News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
              ogType="article"
              slug={RouteFactory.lifestyle}
            />

            <GAData
              title={title}
              level2={"Lifestyle_Index"}
              adblocker={adBlockDetected ? 1 : 0}
            />

            <Ad
              adType="lb1"
              className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
            />

            <PulseEntry />

            <Header
              displayBreadcrumb={
                parentCategory &&
                sectionNavigationItems?.[parentCategory] &&
                isShowBreadCrumb
                  ? true
                  : false
              }
              parentCategory={parentCategory}
              childCategory={childCategory}
              categoryDataMapping={sectionNavigationItems}
            />

            {parentCategory && sectionNavigationItems?.[parentCategory] ? (
              <div className="flex-none">
                <Container>
                  <h1 className="pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850 md:text-8xl">
                    Lifestyle
                  </h1>

                  <div ref={breadcrumbsRef}>
                    <Breadcrumb
                      className="container m-auto"
                      parentCategory={parentCategory}
                      childCategory={childCategory}
                      categoryDataMapping={sectionNavigationItems}
                      extraBreadcrumbItems={
                        <div
                          className={cn(
                            "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm font-bold tracking-tightest text-gray-850 hover:opacity-80"
                          )}
                        >
                          <Link
                            to={`${RouteFactory.lifestyle}?ref=listing-menubar`}
                            reloadDocument
                          >
                            Top stories
                          </Link>
                        </div>
                      }
                    />
                  </div>
                </Container>
              </div>
            ) : null}

            {lifestyle[0] ? (
              <LifestyleHero
                article={lifestyle[0]}
                defaultImage={{
                  directoryName: lifestyle[0].sections?.[0]?.uniqueName,
                }}
              />
            ) : null}

            {lifestyle.length ? <SectionDivider /> : null}

            <div className="container px-4">
              <LifestyleNews
                data={lifestyle.slice(1)}
                parentCategory={parentCategory}
                childCategory={childCategory}
              />

              <div className="flex-1 overflow-hidden">
                <BtLuxeCarousel
                  data={lifestyleCarousel}
                  testid="lifestyle-carousel-list"
                  variations={variant}
                  dotsCustomClass="lifestyle-carousel-dots"
                  btLuxeClass="self-center lg:self-start"
                  disablePaywall={
                    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                  }
                />
              </div>
            </div>

            <Ad adType="catfish" />

            <Ad adType="abm" />

            <Ad adType="prestitial" />
          </div>
        );
      }}
    </Await>
  );
}

import { Await } from "react-router-dom";
import { TermContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { toTitleCase } from "@util/helpers";
import { HttpStatusCode } from "axios";

import { LayoutFactory } from "./helpers";

export default function SectionPage({
  testId = null,
}: {
  testId?: string | null;
}): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<TermContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: TermContext) => {
        if (["section", "keyword"].includes(kind)) {
          const Layout = LayoutFactory(data.urlPath);

          const [parent, child] = data.urlPath.replace(/^\/+/, "").split("/");
          const parentName = parent?.replaceAll("-", " ");
          const childName = child?.replaceAll("-", " ");

          const gaTitle = data.urlPath.endsWith("/latest")
            ? `${parentName} Latest Index`
            : `${parentName} Index`;

          return (
            <>
              <GAData
                chapter1={childName || ""}
                level2={parentName || ""}
                title={toTitleCase(gaTitle)}
                adblocker={typeof window !== undefined ? 0 : window.canRunAds}
              />

              <Layout data-testid={testId} data={data} />
            </>
          );
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
